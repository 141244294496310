import React, { useEffect, useState } from 'react';
import parachuteImg from '../parachute.png';
import logoToken from '../logoToken.svg';
import { ERC20_ABI } from "../abis/erc20";
import QtechStakingABI from '../abis/QtechStakingABI.json';
import NodesABI from '../abis/NodesABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Button, Alert, Card, Container } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import LevelsLegend from '../components/LevelsLegend';
import CoinSpin from '../components/CoinSpin';
import RefferalComponent from '../components/RefferalComponent';
import LevelComponent from '../components/LevelComponent';
import { useNavigate } from 'react-router-dom';
import StripeLoaderImg from '../stripe_loader.svg';
import { BiInfoCircle, BiBox, BiCoin, BiCalendar, BiCheckCircle, BiListCheck } from 'react-icons/bi';
import metaMaskIcon from '../metaMask.svg';
import HeroSection from './HeroSection';
import HowItWorks from './HowItWorks';
import NodePackages from './NodePackages';
import PricingSection from './PricingSection';
import Distribution from './Distribution';
import AirdropExport from './AirdropExport';

function Dashboard({ provider, address, isConnected }) {
  const [usdtBalance, setUsdtBalance] = useState('0.00');
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [airdropPower, setAirdropPower] = useState('0.00');
  const [totalNodesAmount, setTotalNodesAmount] = useState('0.00');

  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const navigate = useNavigate();



  const trunTokenAddress = `${SETTINGS.tokenAddress.slice(0, 4)}...${SETTINGS.tokenAddress.slice(-1 * (4))}`;
  const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(
      SETTINGS.collection,
      CollectionABI,
      signer
    );
    
  };
  const importTokenToMetamask = async () => {
    const tokenAddress = SETTINGS.tokenAddress;
    const tokenSymbol = SETTINGS.tokenSymbol;
    const tokenDecimals = 18;
    const tokenImage = logoToken;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: "https://dapp.fingermonkeys.com/logoMM.png",
          },
        },
      });
    } catch (error) {
      console.log("Error importing token to Metamask:", error);
    }
  };

  const countUserNFTs = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);
      const nftsForUser = await contract.getAllNFTsForUser(address);
      const serialized = JSON.stringify(nftsForUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const nfts = JSON.parse(serialized);
      const nftDataPromises = nfts.map(async (nft) => {
        const tokenType = await collectionContract.tokenTypes(parseInt(nft[1]));
        return {
          rawAirdropPower: parseFloat(SETTINGS.NODE_INFO[tokenType.toString()].airdrop_power),
        };
      });
      const nftData = await Promise.all(nftDataPromises);
      let totalAirdropPow = 0;
      nftData.forEach((temp) => {
        totalAirdropPow += temp.rawAirdropPower;
      });
      setTotalNodesAmount(nftsForUser.length);
      setAirdropPower(totalAirdropPow.toFixed(2));
    }
    catch (e) {
      console.log("count user nfts error");
      console.log(e);
    }
  }

  async function getBalance() {
    if (!isConnected)
      setLoadingBalance(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      //const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
      //const USDTBalance = await USDTContract.balanceOf(address);
      //setUsdtBalance(parseFloat(formatUnits(USDTBalance, 6)).toFixed(2));
      const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(address);
      setTokenBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));
      await getUserLevel();
      await countUserNFTs();
    }
    catch (error) {
      console.log("error getBalance");
      console.log(error);
    }
    setLoadingBalance(false);
  }
  const openBuyToken = () => {
    const url = "https://app.uniswap.org/swap?chain=base&inputCurrency=NATIVE&outputCurrency=0x45f368693eee8e3610270f82085784c1c8ac124b"; // Replace with your desired link
    window.open(url, "_blank");
  }
  const openStakeToken = () => {
    navigate('/staking');
  }
  const openNodesList = () => {
    navigate('/list');
  }
  const openHistory = () => {
    navigate('/history');
  }
  const openFAQs = () => {
    navigate('/FAQs');
  }
  const openShop = () => {
    navigate('/store');
  }
  const openLore = () => {
    navigate('/lore');
  }
  const closeStake = async (stakeId) => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      setLoading(true);
      setTxMessage("Closing Stake...");
      const contract = new Contract(SETTINGS.stakingContractQtech, QtechStakingABI, signer);
      const tx = await contract.completeStake(parseInt(stakeId));
      await tx.wait();
      setTxMessage("Stake closed!");
    }
    catch (error) {
      console.error("Error closing stake:", error);
      setTxMessage("Failed to close stake. Please try again.");
    }
    finally {
      await getBalance();
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };
  
  useEffect(() => {
    if (isConnected) {
      getBalance();
    } else {
      setTokenBalance("0.00");
 
    }
  }, [isConnected, provider]);

  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <img src={StripeLoaderImg} style={{ marginTop: "300px" }} />
        <br />
        <h6 className='loaderMsg'>{txMessage}</h6>
      </div>
    );
  }



  return (
    <Container>
      

 

      {/* Title */}
      <h2 className='pageTitle d-none'>Dashboard</h2>

<HeroSection />

      {/* Dashboard
      <Row className="mt-4 mb-md-3 mb-0 ">
      <Col sm={12} md={12} lg={12} xl={12} className="mb-1">
        <AirdropExport />
        </Col>
        </Row> */}
      <Row className="mt-4 mb-md-3 mb-0 ">
        {/* QNODES */}
        
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1">
        <Card className='card-fix text-center pt-3 chat-message new_block_1'>
            <Card.Title className='mt-md-3 mt-2' style={{color: "#fff"}}>
              FM NODES
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0' >
              <LevelComponent provider={provider} isConnected={isConnected} nodeAmount={totalNodesAmount} />
              <Button variant="primary" className='buton mb-3' onClick={openNodesList} >
                MY FM NODES
              </Button>
              

        
            
            </Card.Body>
      
          </Card>
          </Col>
        
          <Col sm={12} md={6} lg={4} xl={4} className="mb-1">
          <Card className='card-fix text-center pt-3 chat-message card-height new_block_2'>
            <Card.Title className='mt-md-3 mt-2' style={{color: "#fff"}}>
              FMT TOKEN
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0'>
              <Row className='mt-4 align-items-center'>
                <Col xs={12} md={12}>
                  <center style={{ height: "50px" }}>
                    <CoinSpin />
                  </center>
                </Col>
            
              </Row>
           
              <div className="mt-auto">
              
                <Col xs={12} md={12} className=''>
                
                <br />
                <br />
                <br />
                  <Row className='mb-3'>
                {/** 
                    <Col><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openBuyToken} className='buton' style={{ height: "42.5px", fontSize: "15px" }}>
                      <BiCoin className='me-1' /> BUY
                    </Button></Col>
                */}
                    <Col><Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openStakeToken} className='buton' style={{ height: "42.5px", fontSize: "15px" }}>
                      <BiBox className='me-1' /> STAKE
                    </Button></Col>
                  </Row>
                </Col>
                <Button variant="primary" className='buton buton-secondary mb-0' onClick={importTokenToMetamask}>
                  IMPORT TO
                  <img src={metaMaskIcon} style={{ width: "20px", marginTop: "-3px", marginLeft: "5px" }} alt="metaMaskIcon" />
                </Button>
                <hr />
                <h6 className='fw-normal mb-0 pt-0 small'>
                  <small style={{color: "#fff"}}>Your balance:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
                  <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalance)} {SETTINGS.tokenSymbol}</b>
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6} lg={4} xl={4} className="mb-1">
        <Card className='card-fix text-center pt-3 chat-message card-height new_block_3'>
            <Card.Title className='mt-md-3 mt-2' style={{color: "#fff"}}>
              REFERRAL
            </Card.Title>
            <Card.Body className='d-flex flex-column pt-0' >
            <RefferalComponent provider={provider} isConnected={isConnected} nodeAmount={totalNodesAmount} />
            {/** 
            <Button variant="primary buton mt-2 d-flex align-items-center justify-content-center" onClick={openHistory} className='buton' style={{ height: "42.5px", fontSize: "15px" }}>
                      <BiListCheck className='me-1' /> History
                    </Button>
                       */}
            </Card.Body>
   
          </Card>
          </Col>
          
      <HowItWorks />
      <PricingSection />
      <NodePackages />


      <section id="node-packages" className="py-5 pb-3 mt-5" style={{backgroundColor: "#111",border: "1px solid #453A37",borderRadius: "10px"}}>
      <div className="container">
        <h2 className="section-title text-center mb-4">FM Node Video Introduction</h2>
        <video poster="https://fingermonkeys.com/video-preview.png" controls style={{borderRadius: "10px",aspectRatio: "16/9",width: "100%"}}>
                    <source src="https://fingermonkeys.com/video/finger-monkeys-video-text.mp4" type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video>
        </div>
        </section>
      
      <Distribution  />
        <Col sm={12} md={6} lg={12} xl={12} className="mb-1 mt-5">

<Row className="mb-0 pb-2">

        <Col xs={12} md={6} className="mb-1">
          <div className='nodeSection new_block_4'>
            <center>
              <h5 className='mt-md-4 mt-0'>BECOME<br className='d-md-none d-block'></br> A GUARDIAN!</h5>
              <p>With our unique approach and Earn2Play ecosystem we are paving the future of mini games gaming experiences.</p>
              <a href="https://t.me/GuardiansOfFingerMonkeysBot" target='_blank'className='buton mt-1' style={{ maxWidth: "300px" }}>
                PLAY
              </a>
            </center>
          </div>
        </Col>
       


     
        <Col xs={12} md={6} className="mb-1">
          <div className='nodeSection new_block_5'>
            <center>
              <h5>Fingermonkeys.com</h5>
              <p>Visit our main page, for info about our project, where are we at stages, what is FingerMonkeys all about and more. </p>
              <a href="https://www.fingermonkeys.com/" target="_blank" onClick={openFAQs} className='buton mt-1' style={{ maxWidth: "300px" }}>
                GO TO WEBSITE
              </a>
            </center>
          </div>
        </Col>
        
      </Row>

</Col>


      </Row>



      
    </Container>
  );
}

export default Dashboard;
