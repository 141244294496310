import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { Card } from 'react-bootstrap';
import boxImg from '../box.png';

const NodeType = 'NODE';
const DraggableNode = ({ nft, onClick }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: NodeType,
    item: { id: nft.tokenId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  drag(ref);

  return (
    <div
      ref={ref}
      style={{ padding: "0px",border:"1px solid #453A37",backgroundColor:"#000", opacity: isDragging ? 0.5 : 1, marginBottom: '1rem' }}
      className='card-fix'
      onClick={() => onClick(nft)}
    >
      <Card style={{background: "transparent"}}>
        <Card.Body>
          <Card.Img variant="top" src={nft.imageURL || boxImg} alt={`FM NODE ${nft.tokenId}`} className='height70'/>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DraggableNode;
