// src/components/Nodes.js (or CardNode.jsx)
import React, { useState } from 'react';
import { Button, Spinner, Card, ProgressBar, Col } from 'react-bootstrap';
import { BrowserProvider, Contract, parseUnits } from 'ethers';
import NodesABI from '../abis/NodesABI.json';
import SETTINGS from '../SETTINGS';
import parachuteImg from '../parachute.png';
import logoToken from '../logoToken.svg';

const CardNode = ({ nft, provider, getBalanceFunction, amount = 0 }) => {
  const [loading, setLoading] = useState(false);
  const [txMessage, setTxMessage] = useState('');

  // This calculates how much of the 24-hour cycle has elapsed
  const getProgress = (timestamp) => {
    const now = Math.floor(Date.now() / 1000);
    const timePassed = now - timestamp;
    const progress = (timePassed / 86400) * 100;
    return Math.min(progress, 100);
  };

  // Claim QVRS function
  const claimQvrs = async () => {
    try {

      setLoading(true);
      setTxMessage("Claiming FMT...");
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);

      const claimTx = await nftContract.claimRewards(nft.tokenId);
      await claimTx.wait();

      // Update local state
      nft.unclaimedRewards = 0;
      nft.lastClaim = Math.floor(Date.now() / 1000); // update the local lastClaim so progress bar resets
      setTxMessage("Claimed FMTS successfully!");
    } catch (error) {
      console.error("Error claiming FMT:", error);
      setTxMessage("Failed to Claim FMT. Please try again.");
    } finally {
      setLoading(false);
      // Optionally, clear the message after some delay
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  // Claim USDC function (for type-3 nodes)
  const claimUsdc = async () => {
    try {

      setLoading(true);
      setTxMessage("Claiming USDC...");
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const nftContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);

      // Call the contract function to claim USDC
      const claimTx = await nftContract.claimNodeUsdc(nft.tokenId);
      await claimTx.wait();

      // Update local state
      nft.unclaimedUsdc = 0;
      setTxMessage("Claimed USDC successfully!");
    } catch (error) {
      console.error("Error claiming USDC:", error);
      setTxMessage("Failed to Claim USDC. Please try again.");
    } finally {
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  // Show spinner if any claim is in progress
  if (loading) {
    return (
      <Col xs={12}>
        <Card className='card-fix text-center chat-message-2 h-100 loading-card'>
          <Card.Body className='d-flex fled-flex flex-column card-padding'>
            <div className="text-center">
              <div className="loaderclaim"></div>
              <p className='loaderMsg'><div className="loadertext"></div></p>
              <br />
              <small>{txMessage}</small>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  return (
    <Card className='card-fix text-center chat-message h-100 qwrlds-card' style={{background:"#000"}}>
      <Card.Body className='d-flex fled-flex flex-column card-padding'>
        <Card.Title className='h6 mt-md-0 mt-3 mb-md-2 mb-0'>
          {nft.name}
        </Card.Title>
       {/* <Card.Img variant="top" src={nft.imageURL} alt={`QNODE ${nft.tokenId}`} />*/}
        <div className="d-flex justify-content-center align-items-center">
                                    <img style={{width:"70px"}} src={SETTINGS.NODE_INFO[nft.tokenType].imgUrl} title="External Content" />
                                </div>
        <div className='mt-md-3 mt-0'>
          {/* If the node has a dailyReward, it’s an active node */}
          {nft.dailyReward > 0 ? (
            <>
              <span className='tokenCardId'>
                <small>ID:</small> {nft.tokenId}
              </span>

              <h6 className='fw-normal mb-3 pt-2'>
                <small style={{color: "#fff"}}>Daily:</small>{" "}
                <img
                  src={logoToken}
                  className='tokenIconColor'
                  style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }}
                  alt={SETTINGS.tokenSymbol}
                />
                <b>
                  {new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3,
                  }).format(nft.dailyReward)} {SETTINGS.tokenSymbol}
                </b>
              </h6>

         
              {/* Button to claim QVRS (if unclaimedRewards > 0) */}
              {nft.unclaimedRewards > 0 ? (
                <Button
                  variant="primary"
                  onClick={claimQvrs}
                  className="buton buton-secondary buton-sec-small"
                >
                  CLAIM {nft.unclaimedRewards} {SETTINGS.tokenSymbol}
                </Button>
              ) : (
                <div>
                  <Button
                    variant="primary"
                    disabled
                    className="buton buton-disabled buton-secondary buton-sec-small"
                  >
                    <b>CLAIM {nft.dailyReward} {SETTINGS.tokenSymbol}</b>
                  </Button>
                  <ProgressBar
                    style={{ height: "5px", marginTop: "5px" }}
                    animated
                    striped
                    now={getProgress(nft.lastClaim)}
                  />
                </div>
              )}

              {/* Button to claim USDC (for type-3 nodes only, if unclaimedUsdc > 0) */}
              {parseInt(nft.tokenType) === 3 && parseFloat(nft.unclaimedUsdc) > 0 ? (
                <div className='mt-1'>
                  <Button
                    variant="secondary"
                    onClick={claimUsdc}
                    className="buton buton-secondary buton-sec-small"
                  >
                    CLAIM {nft.unclaimedUsdc} USDC
                  </Button>
                </div>
              ):(
                <div className='mt-1'>
                  <Button
                    variant="secondary"
                    disabled
                    className="buton buton-disabled buton-secondary buton-sec-small"
                  >
                    No USDC to Claim
                  </Button>
                </div>
              )}

            </>
          ) : (
            // This section might be for other placeholders or different states
            <>
              <h6 className='pt-2 mb-3 fw-normal'>
                <small>Amount:</small> <b>{amount}x</b>
              </h6>
              <h6 className='fw-normal mt-2 pt-1 mb-0'>
                <img
                  src={parachuteImg}
                  className='tokenIconColor'
                  style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }}
                  alt={SETTINGS.tokenSymbol}
                />
                <b>
                  {new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(nft.rawAirdropPower * amount)} AP
                </b>
              </h6>
            </>
          )}
        </div>
      </Card.Body>

      {/* Optionally display a transaction message below */}
      {txMessage && (
        <div className="p-2 text-center">
          <small className="text-muted">{txMessage}</small>
        </div>
      )}
    </Card>
  );
};

export default CardNode;
