import React from "react";
import { BiCheck, BiX } from 'react-icons/bi';

const NodePackages = () => {
  return (
    <section id="node-packages" className="py-5 mt-5" style={{backgroundColor: "#111",border: "1px solid #453A37",borderRadius: "10px"}}>
      <div className="container">
        <h2 className="section-title text-center mb-4">FM Nodes Packages</h2>
        <p className="text-center mb-5" style={{color: "#FFB800"}}>
          Compare the benefits and features each type of node provides.
        </p>

        <div
          className="table-responsive white-card mx-auto"
          
        >
          <table className="table table-bordered table-striped text-center align-middle" style={{marginLeft: "10px",marginRight: "10px", width: "calc(100% - 20px)",backgroundColor: "#000"}}>
            <thead className="table-light2" style={{backgroundColor: "#333 !important"}}>
              <tr>
                <th scope="col" style={{textAlign: "left",color: "#aaa"}}>Node Rewards</th>
                <th scope="col" style={{color: "#aaa"}}>CANOPY</th>
                <th scope="col" style={{color: "#aaa"}}>TEMPLE</th>
                <th scope="col" style={{color: "#aaa"}}>APEX</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{borderBottom: "1px solid #333"}}>
                <td className="text-start">
                  <strong>FMT Minting</strong>
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
              </tr>
              <tr style={{borderBottom: "1px solid #333"}}>
                <td className="text-start">
                  <strong>Referral First Line (8%)</strong>
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
              </tr>
              <tr style={{borderBottom: "1px solid #333"}}>
                <td className="text-start">
                  <strong>Referral Second Line (2%)</strong>
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
              </tr>
              <tr style={{borderBottom: "1px solid #333"}}>
                <td className="text-start">
                  <strong>Voting System / Treasury</strong>
                </td>
                <td>
                 <BiX
                    className="bi bi-x text-danger"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
              </tr>
              <tr style={{borderBottom: "1px solid #333"}}>
                <td className="text-start">
                  <strong>Game event Rewards 5%</strong>
                </td>
                <td>
                 <BiX
                    className="bi bi-x text-danger"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
              </tr>
              <tr style={{borderBottom: "1px solid #333"}}>
                <td className="text-start">
                  <strong>Game event Rewards 25%</strong>
                </td>
                <td>
                 <BiX
                    className="bi bi-x text-danger"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiX
                    className="bi bi-x text-danger"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-start">
                  <strong>USDC Rewards</strong>
                </td>
                <td>
                 <BiX
                    className="bi bi-x text-danger"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                 <BiX
                    className="bi bi-x text-danger"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
                <td>
                  <BiCheck
                    className="bi bi-check2 text-success"
                    style={{ fontSize: "1.3rem" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        
      </div>
    </section>
  );
};

export default NodePackages;
