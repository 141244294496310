import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Card, Table, Button } from "react-bootstrap";
import QvrseNodesABI from "../abis/NodesABI.json";
import SETTINGS from "../SETTINGS";

const CONTRACT_ADDRESS = SETTINGS.nodeContract;

// Node metadata for tokenType=1,2,3
const nodeDetails = [
  {
    name: "CANOPY",
    price: 110,
    daily: 0.091,
    iframeUrl: "https://node.qvrse.io/",
  },
  {
    name: "TEMPLE",
    price: 210,
    daily: 0.182,
    iframeUrl: "https://node1.qvrse.io/",
  },
  {
    name: "APEX",
    price: 1000,
    daily: 0.91,
    iframeUrl: "https://node1337.qvrse.io/",
  },
];

// Helper: Truncate an Ethereum address (e.g. 0x1234...abcd)
const truncateAddress = (address) => {
  if (!address) return "";
  return address.slice(0, 6) + "..." + address.slice(-4);
};

// Helper: Convert tokenType (1,2,3) => Node Name
const getNodeName = (tokenTypeString) => {
  const idx = parseInt(tokenTypeString) - 1;
  if (idx >= 0 && idx < nodeDetails.length) {
    return nodeDetails[idx].name;
  }
  return `Unknown Type #${tokenTypeString}`;
};

const TxHistory = ({ provider }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Example: the contract was deployed around block 16811741 (BSC test?), adjust as needed
  const START_BLOCK = 16811741;

  useEffect(() => {
    if (!provider) return;
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      setError("");

      const signerOrProvider = new ethers.BrowserProvider(provider);
      const etherSigner = await signerOrProvider.getSigner();
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        QvrseNodesABI,
        etherSigner
      );

      // Build filters
      const referralFilter = contract.filters.ReferralReward(
        null,
        null,
        null,
        null
      );
      const buyFilter = contract.filters.BuyWorld(null, null, null);
      const claimFilter = contract.filters.RewardClaimed(null, null);

      // Query logs
      const [referralLogs, buyLogs, claimLogs] = await Promise.all([
        contract.queryFilter(referralFilter, START_BLOCK, "latest"),
        contract.queryFilter(buyFilter, START_BLOCK, "latest"),
        contract.queryFilter(claimFilter, START_BLOCK, "latest"),
      ]);

      // Parse logs => objects
      const referralTxs = referralLogs.map((log) => {
        const parsed = contract.interface.parseLog(log);
        return {
          event: "ReferralReward",
          blockNumber: log.blockNumber,
          user: parsed.args.user,
          referrer: parsed.args.referrer,
          // Convert from raw USDC (1e6) to a more readable number, e.g. 123.45
          usdcValue: (Number(parsed.args.usdcValue) / 1e6).toFixed(2),
          level: parsed.args.level.toString(),
        };
      });

      const buyTxs = buyLogs.map((log) => {
        const parsed = contract.interface.parseLog(log);
        return {
          event: "BuyWorld",
          blockNumber: log.blockNumber,
          user: parsed.args.user,
          quantity: parsed.args.quantity.toString(),
          tokenType: parsed.args.tokenType.toString(),
        };
      });

      const claimTxs = claimLogs.map((log) => {
        const parsed = contract.interface.parseLog(log);
        return {
          event: "RewardClaimed",
          blockNumber: log.blockNumber,
          user: parsed.args.user,
          amount: parsed.args.amount.toString(),
        };
      });

      let allTxs = [...referralTxs, ...buyTxs, ...claimTxs];
      allTxs.sort((a, b) => b.blockNumber - a.blockNumber);

      setTransactions(allTxs);
    } catch (err) {
      console.error("Error fetching events:", err);
      setError("Failed to load transaction history.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      
      <h3 className="pageTitle">Transaction History</h3>
      {loading && <p>Loading transaction history...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!loading && !error && transactions.length === 0 && (
        <p>No transactions found.</p>
      )}

      {/* Display the transactions in a Card with a styled table */}
      {!loading && transactions.length > 0 && (
        <Card className="card-fix pt-3 chat-message">
          <Card.Body className="d-flex flex-column pt-0">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Event</th>
                  <th>User</th>
                  <th>Block #</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((tx, idx) => (
                  <tr key={idx}>
                    <td>{tx.event}</td>
                    <td>{truncateAddress(tx.user)}</td>
                    <td>{tx.blockNumber}</td>
                    <td>
                      {tx.event === "ReferralReward" && (
                        <>
                          <div>Referrer: {truncateAddress(tx.referrer)}</div>
                          <div>USDC Value: {tx.usdcValue}</div>
                          <div>Level: {tx.level}</div>
                        </>
                      )}
                      {tx.event === "BuyWorld" && (
                        <>
                          <div>Quantity: {tx.quantity}</div>
                          <div>
                            Token Type: {tx.tokenType} ({" "}
                            {getNodeName(tx.tokenType)} )
                          </div>
                        </>
                      )}
                      {tx.event === "RewardClaimed" && (
                        <>
                          <div>Claimed Amount: {tx.amount}</div>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}

  
    </div>
  );
};

export default TxHistory;
