import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import NodeCard from './NodeCard';
import GalaxyImg from '../galaxy.png';
import FlaskImg from '../flask.svg';
import { Card, Alert, Button, Row, Col } from 'react-bootstrap';
import { BrowserProvider, Contract } from 'ethers';
import SETTINGS from "../SETTINGS";
import CollectionABI from '../abis/CollectionABI';
const NodeType = 'NODE';

const DropContainer = ({ node, setNode, isOver, drop }) => (
  <div
    ref={drop}
    className='pokemon-card mb-2'
    style={{
      border: '2px dashed rgba(23, 181, 2, 0.5)',
      minHeight: '65px',
      backgroundColor: isOver ? 'var(--maincolor)' : 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px',
    }}
  >
    {node ? (
      <NodeCard
        id={node.tokenId}
        name={node.name}
        imageURL={node.imageURL}
        unclaimedRewards={node.unclaimedRewards}
        tokenType={node.tokenType}
      />
    ) : (
      <p className='my-0' style={{ textAlign: 'center', color: 'rgba(255, 255, 255, 0.8)' }}>
        <small>Drag here</small>
      </p>
    )}
  </div>
);

const MergeContainer = ({
  provider, address, ownedNFTs, fetchOwnedNFTs, hideCard,
  node1, node2, node3, node4, node5, node6, node7, node8, node9, node10,
  setNode1, setNode2, setNode3, setNode4, setNode5, setNode6, setNode7, setNode8, setNode9, setNode10
}) => {
  const [mergeResult, setMergeResult] = useState(null);
  const [mergeType, setMergeType] = useState(null);
  const [txMessage, setTxMessage] = useState('Drop nodes of the same type to craft them.');
  const [loading, setLoading] = useState(false);
  const [mergeInitiated, setMergeInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [{ isOver1 }, drop1] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode1(node);
        hideCard(node.tokenId);
        craftResult(1, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver1: monitor.isOver(),
    }),
  });
  const [{ isOver2 }, drop2] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode2(node);
        hideCard(node.tokenId);
        craftResult(2, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver2: monitor.isOver(),
    }),
  });
  const [{ isOver3 }, drop3] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode3(node);
        hideCard(node.tokenId);
        craftResult(3, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver3: monitor.isOver(),
    }),
  });
  const [{ isOver4 }, drop4] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode4(node);
        hideCard(node.tokenId);
        craftResult(4, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver4: monitor.isOver(),
    }),
  });
  const [{ isOver5 }, drop5] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode5(node);
        hideCard(node.tokenId);
        craftResult(5, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver5: monitor.isOver(),
    }),
  });

  const [{ isOver6 }, drop6] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode6(node);
        hideCard(node.tokenId);
        craftResult(6, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver6: monitor.isOver(),
    }),
  });

  const [{ isOver7 }, drop7] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode7(node);
        hideCard(node.tokenId);
        craftResult(7, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver7: monitor.isOver(),
    }),
  });

  const [{ isOver8 }, drop8] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode8(node);
        hideCard(node.tokenId);
        craftResult(8, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver8: monitor.isOver(),
    }),
  });

  const [{ isOver9 }, drop9] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode9(node);
        hideCard(node.tokenId);
        craftResult(9, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver9: monitor.isOver(),
    }),
  });

  const [{ isOver10 }, drop10] = useDrop({
    accept: NodeType,
    drop: (item) => {
      const node = ownedNFTs.find(nft => nft.tokenId === item.id);
      if (node) {
        setNode10(node);
        hideCard(node.tokenId);
        craftResult(10, node.tokenType);
      }
    },
    collect: (monitor) => ({
      isOver10: monitor.isOver(),
    }),
  });

  const clearBoxes = () => {
    setNode1(null);
    setNode2(null);
    setNode3(null);
    setNode4(null);
    setNode5(null);
    setNode6(null);
    setNode7(null);
    setNode8(null);
    setNode9(null);
    setNode10(null);
    setMergeResult(null);
    fetchOwnedNFTs();
  };

  const getTokenTypeOrEmpty = (node) => {
    if (node) return node.tokenType;
    return 0;
  };

  const getTokenIdOrEmpty = (node) => {
    if (node) return node.tokenId;
    return 0;
  };

  

  const craftResult = (nodePosition, nodeType) => {
    let types = [getTokenTypeOrEmpty(node1), getTokenTypeOrEmpty(node2), getTokenTypeOrEmpty(node3), getTokenTypeOrEmpty(node4), getTokenTypeOrEmpty(node5), getTokenTypeOrEmpty(node6), getTokenTypeOrEmpty(node7), getTokenTypeOrEmpty(node8), getTokenTypeOrEmpty(node9), getTokenTypeOrEmpty(node10)];
    types[nodePosition - 1] = nodeType;
    const craft_items = types.join("_");
    console.log(craft_items);
    if (SETTINGS.CRAFT_DICT[craft_items]) {
      const imgUrl = SETTINGS.CRAFT_DICT[craft_items][0];
      const newType = SETTINGS.CRAFT_DICT[craft_items][1];
      setMergeResult(imgUrl);
      setMergeType(newType);
    } else {
      setMergeResult(null);
      setMergeType(null);
    }
  };

  const mergeTokens = async () => {
    // Gather all dropped nodes into an array
    const droppedNodes = [
      node1, node2, node3, node4, node5,
      node6, node7, node8, node9, node10
    ].filter((n) => n !== null);
  
    // Must have exactly 5 or 10
    if (droppedNodes.length !== 5 && droppedNodes.length !== 10) {
      setErrorMessage("You must drop exactly 5 type-2 nodes OR 10 type-1 nodes.");
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }
  
    // Check that all dropped nodes share the same tokenType
    const firstType = droppedNodes[0].tokenType;
    console.log(firstType);
    const allSameType = droppedNodes.every((n) => n.tokenType === firstType);
  
    if (!allSameType) {
      setErrorMessage("All dropped nodes must be the same type.");
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }
  
    // If length is 10, they must be type-1
    if (droppedNodes.length === 10 && parseInt(firstType) !== 1) {
      setErrorMessage("For 10 nodes, they must be type-1.");
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }
  
    // If length is 5, they must be type-2
    if (droppedNodes.length === 5 && parseInt(firstType) !== 2) {
      setErrorMessage("For 5 nodes, they must be type-2.");
      setTimeout(() => setErrorMessage(''), 5000);
      return;
    }
  
    // All checks passed. Now craft!
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const nftContract = new Contract(SETTINGS.collection, CollectionABI, signer);
  
    // Prepare tokenIds array for the contract call
    const craftTokensArr = droppedNodes.map((n) => n.tokenId);
  
    try {
      setLoading(true);
      setTxMessage("Crafting ...");
  
      const tx = await nftContract.craft(craftTokensArr);
      await tx.wait();
  
      setTxMessage("FM NODE crafted successfully!");
      console.log("FM NODE crafted successfully");
      clearBoxes();  // Clears all drop containers, refreshes user’s NFTs
    } catch (error) {
      console.error("Error crafting nodes:", error);
      setTxMessage("Failed to craft. Please try again.");
    } finally {
      setLoading(false);
      setMergeInitiated(false); // If you still use this flag
      fetchOwnedNFTs();         // Re-fetch the user’s NFTs
      setTimeout(() => setTxMessage("Drop nodes to craft."), 5000);
    }
  };
  

  useEffect(() => {
    // Check for craft results whenever node1, node2, node3, or node4 changes
    craftResult(1, getTokenTypeOrEmpty(node1));
    craftResult(2, getTokenTypeOrEmpty(node2));
    craftResult(3, getTokenTypeOrEmpty(node3));
    craftResult(4, getTokenTypeOrEmpty(node4));
    craftResult(5, getTokenTypeOrEmpty(node5));
    craftResult(6, getTokenTypeOrEmpty(node6));
    craftResult(7, getTokenTypeOrEmpty(node7));
    craftResult(8, getTokenTypeOrEmpty(node8));
    craftResult(9, getTokenTypeOrEmpty(node9));
    craftResult(10, getTokenTypeOrEmpty(node10));
  }, [node1, node2, node3, node4, node5, node6, node7, node8, node9, node10]);

  return (
    <div>
      {loading ? (
        <div className='pokemon-card loaderCard'>
          <center>
            <br />
            <img src={FlaskImg} />
            <br /><br />
          </center>
          <h6 className='text-center'>{txMessage}</h6><br /><br />
        </div>
      ) : (
        <div className='pokemon-card'>
          <Row>
            <Col xs={12} md={12}>
              <Row>
                <Col xs={12} md={3}></Col>
                <Col xs={12} md={6}>
                  <div className='pokemon-card'>
                    {mergeResult ? (
                      <Card.Img variant="top" src={mergeResult} style={{ height: "200px" }} />
                    ) : (
                      <div style={{ height: "200px" }} className='d-flex align-items-center justify-content-center'>
                        <Card.Img variant="top" src={GalaxyImg} style={{ height: "200px", opacity: "0.3", display: "none" }} />
                        <p className='my-0' style={{ color: 'rgba(255, 255, 255, 1)' }}>
                          Craft result here
                        </p>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <br />
            </Col>
            {/* Dropzone */}
            <Col xs={12} md={12} className='mt-md-0 mt-4'>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Row>
              <Col xs={1} md={1}></Col>
                <Col xs={2} md={2}><DropContainer node={node1} setNode={setNode1} isOver={isOver1} drop={drop1} /></Col>
                <Col xs={2} md={2}><DropContainer node={node2} setNode={setNode2} isOver={isOver2} drop={drop2} /></Col>
                <Col xs={2} md={2}><DropContainer node={node3} setNode={setNode3} isOver={isOver3} drop={drop3} /></Col>
                <Col xs={2} md={2}><DropContainer node={node4} setNode={setNode4} isOver={isOver4} drop={drop4} /></Col>
                <Col xs={2} md={2}><DropContainer node={node5} setNode={setNode5} isOver={isOver5} drop={drop5} /></Col>
                <Col xs={1} md={1}></Col>
                <Col xs={1} md={1}></Col>
                <Col xs={2} md={2}><DropContainer node={node6} setNode={setNode6} isOver={isOver6} drop={drop6} /></Col>
                <Col xs={2} md={2}><DropContainer node={node7} setNode={setNode7} isOver={isOver7} drop={drop7} /></Col>
                <Col xs={2} md={2}><DropContainer node={node8} setNode={setNode8} isOver={isOver8} drop={drop8} /></Col>
                <Col xs={2} md={2}><DropContainer node={node9} setNode={setNode9} isOver={isOver9} drop={drop9} /></Col>
                <Col xs={2} md={2}><DropContainer node={node10} setNode={setNode10} isOver={isOver10} drop={drop10} /></Col>
              </Row>
            </Col>
            {/* Button */}
            <Col xs={6}>
              {node1 && node2 && node1.tokenType === node2.tokenType && mergeResult ? (
                <Button onClick={mergeTokens} disabled={loading} className='mt-3 buton'>
                  CRAFT
                </Button>
              ) : (
                <Button disabled className='mt-3 buton'>
                  CRAFT
                </Button>
              )}
            </Col>
            <Col xs={6}>
              <Button className='mt-3 buton buton-secondary' onClick={clearBoxes} style={{ height: "42.5px", fontSize: "15px" }}>
                CLEAR
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default MergeContainer;
