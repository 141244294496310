import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Alert, Spinner, Button, ProgressBar } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import TokenSaleAndStakingABI from '../abis/TokenSaleAndStakingABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import logoToken from '../logoToken.svg'; 
import { useNavigate } from 'react-router-dom';

const BuyToken = ({ provider, account }) => {
  const [ethBalance, setEthBalance] = useState('0');
  const [usdtBalance, setUsdtBalance] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [presales, setPresales] = useState([]);
  const [selectedPresaleIndex, setSelectedPresaleIndex] = useState(0);
  const [amountToBuy, setAmountToBuy] = useState('');
  const [amountToRecive, setAmountToRecive] = useState('0');
  const [activePresale, setActivePresale] = useState(null);

  const [stakes, setStakes] = useState([]);
  const [distribution, setDistribution] = useState({
    totalAmount: 0,      // total presale tokens allocated
    claimedAmount: 0,    // how many have been claimed so far
    startTime: 0         // distribution start time
  });
  const [isDexLaunched, setIsDexLaunched] = useState(false);
  const [dexLaunchTime, setDexLaunchTime] = useState(0);

  // UI loading/tx states
  const [loading, setLoading] = useState(false);
  const [txMessage, setTxMessage] = useState("");

  const navigate = useNavigate();

  // Helper to calculate how much time has passed out of the 30-day stake period
  const getProgress = (timestamp) => {
    const now = Math.floor(Date.now() / 1000);
    const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
    const timePassed = now - timestamp;
    const progress = (timePassed / thirtyDaysInSeconds) * 100;
    return Math.min(progress, 100); // Cap at 100%
  };

  // --------------- FETCH BALANCES ---------------
  async function getBalance() {
    if (!provider || !account) return;
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    // USDT Balance
    const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
    const USDTBalance = await USDTContract.balanceOf(account);
    const USDTDecimals = await USDTContract.decimals();
    setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));

    // Token Balance
    const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
    const TokenBalance = await TokenContract.balanceOf(account);
    const TokenDecimals = await TokenContract.decimals();
    setTokenBalance(formatUnits(TokenBalance, TokenDecimals));

    // Native ETH / MATIC / etc. Balance
    const balance = await ethersProvider.getBalance(account);
    setEthBalance(formatUnits(balance, 18));
  }

  // --------------- FETCH PRESALES ---------------
  const fetchPresales = async () => {
    if (!provider || !account) return;
    const ethersProvider = new BrowserProvider(provider);
    const contract = new Contract(SETTINGS.mainContractNew, TokenSaleAndStakingABI, ethersProvider);

    // Load presales array
    const pArr = await contract.getAllPresales();
    let data = [];
    for (let i = 0; i < pArr.length; i++) {
      const leftAmount = parseFloat(formatUnits(pArr[i].tokenAmount.toString(), 18)) 
                        - parseFloat(formatUnits(pArr[i].soldAmount.toString(), 18));
      data.push({
        tokenAmount: pArr[i].tokenAmount.toString(),
        tokenAmountDec: formatUnits(pArr[i].tokenAmount.toString(), 18).toString(),
        price: pArr[i].price.toString(),
        priceDec: formatUnits(pArr[i].price.toString(), 18).toString(),
        soldAmount: pArr[i].soldAmount.toString(),
        leftAmount: leftAmount,
        isActive: pArr[i].isActive,
      });
    }
    setPresales(data);

    // Identify currently active presale
    const active = data.find((presale) => presale.isActive);
    setActivePresale(active);
    setSelectedPresaleIndex(data.indexOf(active));
  };

  // --------------- FETCH STAKES ---------------
  const fetchStakes = async () => {
    if (!provider || !account) return;
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.mainContractNew, TokenSaleAndStakingABI, signer);

      const stakesDataUser = await contract.getUserStakes(account);
      // BigInt serialization trick
      const serialized = JSON.stringify(stakesDataUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const stakesData = JSON.parse(serialized);

      let stakesArr = [];
      for (let i = 0; i < stakesData.length; i++) {
        const stake = stakesData[i];
        stakesArr.push({
          amount: formatUnits(stake[0].toString(), 18),
          timestamp: parseInt(stake[1]),
          claimed: stake[2],
          index: i
        });
      }
      setStakes(stakesArr);
    } catch (error) {
      console.error("Failed to fetch stakes", error);
    } finally {
      setLoading(false);
    }
  };

  // --------------- FETCH DISTRIBUTION (PRESALE VEST) ---------------
  const fetchDistribution = async () => {
    if (!provider || !account) return;
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.mainContractNew, TokenSaleAndStakingABI, signer);

      // distributions is public, so we can just do contract.distributions(account)
      const distData = await contract.distributions(account);
      // Also fetch isDexLaunched and dexLaunchTime
      const launched = await contract.isDexLaunched();
      const launchTime = await contract.dexLaunchTime();

      // distData is a struct: [totalAmount, claimedAmount, startTime]
      const serialized = JSON.stringify(distData, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const [totalAmountStr, claimedAmountStr, startTimeStr] = JSON.parse(serialized);

      setDistribution({
        totalAmount: parseFloat(formatUnits(totalAmountStr || "0", 18)),
        claimedAmount: parseFloat(formatUnits(claimedAmountStr || "0", 18)),
        startTime: parseInt(startTimeStr || "0", 10),
      });

      setIsDexLaunched(launched);
      setDexLaunchTime(parseInt(launchTime, 10));
    } catch (error) {
      console.error("Failed to fetch distribution", error);
    } finally {
      setLoading(false);
    }
  };

  // --------------- HOOK: Load data on mount ---------------
  useEffect(() => {
    if (!provider || !account) return;
    fetchPresales();
    getBalance();
    fetchStakes();
    fetchDistribution();
  }, [provider, account]);

  // --------------- Handle user input for buy ---------------
  const _setAmountToBuy = (amount) => {
    setAmountToBuy(amount);
    if (activePresale) {
      const price = parseFloat(activePresale.priceDec);
      const tokensToRecive = parseFloat(amount) / price;
      setAmountToRecive(tokensToRecive.toFixed(4));
    }
  };

  // --------------- BUY TOKENS (Presale) ---------------
  const handleBuyToken = async () => {
    if (!activePresale) return;
    setLoading(true);
    try {
      setTxMessage("Approving USDT transfer...");
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const usdtContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
      const amountToBuyWei = parseUnits(amountToBuy.toString(), 6);

      // Approve the token sale contract to spend USDT
      const approveTx = await usdtContract.approve(SETTINGS.mainContractNew, amountToBuyWei);
      await approveTx.wait();

      setTxMessage("Purchasing tokens...");

      const tokenSaleContract = new Contract(SETTINGS.mainContractNew, TokenSaleAndStakingABI, signer);
      const buyTx = await tokenSaleContract.buyToken(selectedPresaleIndex, amountToBuyWei);
      await buyTx.wait();

      setTxMessage("Tokens purchased successfully!");
    } catch (error) {
      console.error("Transaction failed!", error);
      setTxMessage("Transaction failed. Please try again.");
    } finally {
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
      fetchStakes();
      fetchDistribution();
      getBalance();
    }
  };

  // --------------- CLAIM STAKE (30-day lock) ---------------
  const handleClaimStake = async (stakeIndex) => {
    setLoading(true);
    setTxMessage("Claiming your stake...");
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.mainContractNew, TokenSaleAndStakingABI, signer);

      const tx = await contract.claim(stakeIndex);
      await tx.wait();

      setTxMessage("Stake claimed successfully!");
      // Refresh stakes
      fetchStakes();
    } catch (error) {
      console.error("Claim failed", error);
      setTxMessage("Claim failed. Please try again.");
    } finally {
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  // --------------- CLAIM DISTRIBUTION (Presale Vest) ---------------
  const handleClaimDistribution = async () => {
    setLoading(true);
    setTxMessage("Claiming presale distribution...");
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.mainContractNew, TokenSaleAndStakingABI, signer);

      const tx = await contract.claimDistribution();
      await tx.wait();

      setTxMessage("Distribution claimed successfully!");
      // Refresh distribution
      fetchDistribution();
    } catch (error) {
      console.error("Claim distribution failed", error);
      setTxMessage("Claim distribution failed. Please try again.");
    } finally {
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  // --------------- RENDERING HELPERS ---------------

  // Render the user's distribution (the vesting from presale)
  const renderDistribution = () => {
    const { totalAmount, claimedAmount, startTime } = distribution;

    // If no distribution is set up
    if (totalAmount <= 0) return null;

    // If the DEX is not launched, show "waiting for DEX launch"
    if (!isDexLaunched) {
      return (
        <Card className="mb-3 pokemon-card">
          <Card.Body>
            <h5>Your Presale Vesting</h5>
            <p>Total Presale Tokens: {totalAmount.toLocaleString()} {SETTINGS.tokenSymbol}</p>
            <p>Claimed So Far: {claimedAmount.toLocaleString()} {SETTINGS.tokenSymbol}</p>
            <p className="text-danger">DEX has not launched yet. Please wait.</p>
          </Card.Body>
        </Card>
      );
    }

    // Now, DEX is launched. Let's compute how far along the 365-day vest we are.
    const now = Math.floor(Date.now() / 1000);
    const elapsed = now - dexLaunchTime;
    // cap at 365 days
    const totalVestingTime = 365 * 24 * 60 * 60;
    const clampedElapsed = Math.min(elapsed, totalVestingTime);

    // 10% immediate, 90% linear
    const unlockedSoFar =
      totalAmount * 0.20 +
      (totalAmount * 0.80 * (clampedElapsed / totalVestingTime));

    const newClaimable = Math.max(0, unlockedSoFar - claimedAmount);

    // Progress bar: how much is unlocked out of total
    const distributionProgress = (unlockedSoFar / totalAmount) * 100;

    return (
      <Card className="mb-3 pokemon-card">
        <Card.Body>
          <h5>Your Presale Vesting</h5>
          <Row>
            <Col>
              <p>Total: {totalAmount.toLocaleString()} {SETTINGS.tokenSymbol}</p>
              <p>Claimed: {claimedAmount.toLocaleString()} {SETTINGS.tokenSymbol}</p>
              <ProgressBar
                now={distributionProgress}
                label={`${distributionProgress.toFixed(1)}%`}
                variant="info"
                style={{ height: '20px' }}
                striped
                            animated
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col>
              {newClaimable > 0 ? (
                <Button variant="primary" onClick={handleClaimDistribution}>
                  Claim {newClaimable.toFixed(2)} {SETTINGS.tokenSymbol}
                </Button>
              ) : (
                <small className="text-muted">
                  No additional tokens are vested yet. Check back later.
                </small>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  // --------------- MAIN RENDER ---------------
  if (loading) {
    return (
      <div className="loaderScreen text-center">
        <br /><br /><br /><br />
        <Spinner animation="border" role="status" className='loaderBig' />
        <p className='loaderMsg'>{txMessage}</p>
      </div>
    );
  }

  if (presales.length === 0) {
    return (
      <div className="loaderScreen text-center">
        <br /><br /><br /><br />
        <Spinner animation="border" role="status" className='loaderBig' />
        <p className='loaderMsg'>{txMessage}</p>
      </div>
    );
  }

  // If there's no active presale, we can still show the rest, just a warning
  // (The user may just want to claim distribution, etc.)
  // So we won't return early here, just show a warning in the UI if needed.

  return (
    <div>
      <Row>
        {/* -------------- BUY TOKEN COLUMN -------------- */}
        <Col sm={12} md={8} lg={6}>
          {activePresale ? (
            <>
              <h4>Buy ${SETTINGS.tokenSymbol}</h4>
              <div className='pokemon-card'>
                <div className="buy-token-header text-center" />
                <div className="pokemon-card token-exchange-info">
                  <div>
                    <img src={logoToken} style={{ width: "35px", marginRight: "5px", marginTop: "-5px" }} alt="" />
                    1 ${SETTINGS.tokenSymbol} =
                    <img
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
                      style={{ width: "35px", marginRight: "5px", marginLeft: "10px", marginTop: "-5px" }}
                      alt=""
                    />
                    {activePresale.priceDec} USDC
                  </div>
                  <br />
                </div>

                <div>
                <h5 className='text-center'>Presale #1 is closed</h5>
                  {/** 
                  <div className="input-group text-center">
                    <h5>Enter amount in USDC</h5>
                    <input
                      type="text"
                      value={amountToBuy}
                      onChange={(e) => _setAmountToBuy(e.target.value)}
                      placeholder="Amount in USDC"
                    />
                  </div>
                  <h5>You will receive: {amountToRecive} ${SETTINGS.tokenSymbol}</h5>
                  {parseFloat(activePresale.leftAmount) > 0.1 ? (
                    <Button className="buton" onClick={handleBuyToken}>
                      BUY
                    </Button>
                  ) : (
                    <Button className="buton" disabled>Not available</Button>
                  )}
                  <h5>
                    <img
                      src="https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
                      style={{ width: "18px", marginRight: "10px", marginLeft: "10px", marginTop: "-2px" }}
                      alt=""
                    />
                    <small>Balance: {usdtBalance} USDC</small>
                  </h5>
                  */}
                  <div className="gas-info">
                    ${SETTINGS.tokenSymbol} tokens will start vesting after we launch on DEX.
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Alert variant="warning">
              There are currently no active presales. Please check back later.
            </Alert>
          )}

          {/* Display user distribution vesting info */}
          
        </Col>

        {/* -------------- STAKES & PRESALE COLUMN -------------- */}
        <Col sm={12} md={8} lg={6}>
          {/* User Stakes Section */}
          <div className="staking-container">
            {stakes.length > 0 ? <h4>Your Stakes</h4> : null}
            {renderDistribution()}
            {stakes.map((stake, index) => {
              const progress = getProgress(stake.timestamp);
              return (
                <Card key={index} className="mb-3 pokemon-card">
                  <Card.Body>
                    <Row>
                      <Col xs={8} md={8}>
                        <h6 style={{ marginTop: "15px" }}>
                          <img
                            src={logoToken}
                            className='tokenIconColor'
                            style={{ width: "25px", marginRight: "10px", marginLeft: "10px", marginTop: "-5px" }}
                            alt=""
                          />
                          Staked: {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(stake.amount)}
                          <small> {SETTINGS.tokenSymbol}</small>
                        </h6>
                      </Col>
                      <Col sm={4} md={4}>
                      
                      </Col>
                    </Row>

                    {/* Claim Button / Status */}
                    
                  </Card.Body>
                </Card>
              );
            })}
          </div>

          {/* Presale Summary Section 
          <div style={{ padding: '0 10px' }}>
            <h4>Presales</h4>
            {presales.slice(0, 1).map((presale, i) => (
              <Card key={i} className="mb-3 pokemon-card">
                <Card.Body>
                  <h3>Presale {i + 1}</h3>
                  <h6 style={{ fontSize: '1em' }}>
                    Price: {formatUnits(presale.price.toString(), 'ether')} USDC
                  </h6>
                  <h6 style={{ fontSize: '0.9em' }}>
                    Available in presale: {presale.leftAmount.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2
                    })} {SETTINGS.tokenSymbol}
                  </h6>
                  <h6 style={{ fontSize: '0.9em' }}>
                    {parseFloat(presale.leftAmount) > 0.1 ? (
                      <small>{presale.isActive ? "ACTIVE" : "NOT OPEN YET"}</small>
                    ) : (
                      <small>FINISHED</small>
                    )}
                  </h6>

                  {parseFloat(presale.leftAmount) > 0.1 ? (
                    <ProgressBar
                      striped
                      animated={presale.isActive}
                      now={presale.isActive ? ((presale.leftAmount / presale.tokenAmount) * 100) + 5 : 100}
                      variant={presale.isActive ? "success" : "danger"}
                      style={{ height: '10px', fontSize: '1em' }}
                    />
                  ) : (
                    <ProgressBar
                      striped
                      now={100}
                      variant="success"
                      style={{ height: '10px', fontSize: '1em' }}
                    />
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
          */}
        </Col>
      </Row>
    </div>
  );
};

export default BuyToken;
