import React from "react";
import { BiCheck, BiX } from "react-icons/bi";
import { Row, Col, Button, Alert, Spinner } from "react-bootstrap";

const NodeExtraInfo = () => {
  return (
    <Row
      className="mt-5 mb-5 justify-content-md-center justify-content-start offset-xl-0 offset-lg-2 offset-md-0 offset-sm-0 offset-0"
      style={{
        margin: "0px",
        boxSizing: "border-box",
        width: "calc(100% - 0px)",
        backgroundColor: "#000",
        border: "1px solid #453A37",
        borderRadius: "10px",
      }}
    >
      <Col sm={12} md="{12}" className="mb-0 p-5 align-items-center justify-content-center">
        <h2 style={{ color: "#fff", width: "100%" }}>NODES INFO PANEL</h2>
        <div style={{ marginTop: "15px", color: "#aaa", float: "left", width: "100%" }}>
          <h4>FMT Tokens (Daily Rewards &amp; Game Event Prizes)</h4>
          <p>Your FMT tokens are minted daily. You can claim them anytime and they will be sent directly to your connected wallet. Once claimed, you can stake, hold, or trade them as you prefer.</p>
          <p>
            <strong>Game Event Rewards:</strong> Depending on the type of NODE you own, you will also receive additional rewards from game event prize pools:
          </p>
          <ul>
            <li>Apex (Node 3) Owners receive 30% of the game event prize pool distribution.</li>
            <li>Temple (Node 2) Owners receive 5% of the game event prize pool distribution.</li>
          </ul>
          <p>These rewards will be automatically sent to the wallet connected to your NODE.</p>

          <h4>USDC Rewards (DEX Trading Fees)</h4>
          <p>
            The USDC rewards come from DEX trading fees and are distributed based on trading volume. The amount you receive depends on:
          </p>
          <ul>
            <li>The number of NODE owners sharing the rewards.</li>
            <li>The trading volume on the DEX.</li>
          </ul>
          <p>You can claim your USDC rewards whenever available and use them as you wish.</p>

          <h4>Managing Your NODEs</h4>
          <p>Your NODEs are visible in your wallet, and you have full control over them:</p>
          <ul>
            <li>Hold them to continue receiving rewards.</li>
            <li>Transfer them to another wallet or owner.</li>
            <li>Re-sell them to other interested buyers.</li>
          </ul>

          <h4>Upcoming Marketplace Features</h4>
          <p>We are actively working on expanding your options:</p>
          <ul>
            <li>
              <strong>Official FingerMonkeys Marketplace</strong> – A dedicated marketplace where you can list and sell your NODEs.
            </li>
            <li>
              <strong>External Marketplaces</strong> – We will soon open FingerMonkeys accounts on external NFT marketplaces, allowing you to sell your NODEs if desired.
            </li>
          </ul>
          <p>Stay tuned for more updates, and enjoy your rewards as a NODE owner! 🚀🐵</p>

          <h4>NODE DEX FEE REWARDS PREDICTIONS AND SCENARIOS:</h4>
          <p>
            <strong>Volume rewards scenario</strong>
          </p>
          <div style={{width: "100%",float: "left",overflow: "auto"}}>
            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "15px" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid #453A37", padding: "5px", color: "#fff" }}>Scenario</th>
                  <th style={{ border: "1px solid #453A37", padding: "5px", color: "#fff" }}>Trading Volume (USDT)</th>
                  <th style={{ border: "1px solid #453A37", padding: "5px", color: "#fff" }}>1% of Volume (USDT)</th>
                  <th style={{ border: "1px solid #453A37", padding: "5px", color: "#fff" }}>USDT per Node</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>1</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$1,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$10,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$0.42</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>2</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$10,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$100,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$4.17</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>3</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$100,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$1,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$41.67</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>4</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$500,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$5,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$208.33</td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>5</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$1,000,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$10,000,000</td>
                  <td style={{ border: "1px solid #453A37", padding: "5px" }}>$416.67</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NodeExtraInfo;
