import React, { useEffect } from "react";

const PricingSection = () => {


  return (
    <section id="pricing" className="py-5 mt-5 pricing-section" style={{border: "1px solid #453A37"}}>
      <div className="container">
        <h2 className="section-title">Pricing Overview</h2>
        <p className="text-center" style={{color: "#FFB800"}}>
          Prices increase over 6 rounds. Max APEX nodes: 24,000.
        </p>

        <div className="row price-cards justify-content-center">
          {/* SMALL Card */}
          <div className="col-md-4 mb-4">
            <div className="card custom-pricing-card text-center" style={{backgroundColor: "#000"}}>
              <div className="card-body">
                <i className="bi bi-emoji-neutral icon-style mb-3"></i>
                <h5 className="card-title">CANOPY</h5>
                <p className="card-text">
                  <span style={{color: "#fff"}}>Round 1:</span> $22 <br />
                  <span style={{color: "#fff"}}>Round 2:</span> $28 <br />
                  <span style={{color: "#fff"}}>Round 3:</span> $43 <br />
                  <span style={{color: "#fff"}}>Round 4:</span> $58 <br />
                  <span style={{color: "#fff"}}>Round 5:</span> $90 <br />
                  <span style={{color: "#fff"}}>Round 6:</span> $180
                </p>
              </div>
            </div>
          </div>

          {/* MEDIUM Card */}
          <div className="col-md-4 mb-4">
            <div className="card custom-pricing-card text-center" style={{backgroundColor: "#000"}}>
              <div className="card-body">
                <i className="bi bi-emoji-smile icon-style mb-3"></i>
                <h5 className="card-title">TEMPLE</h5>
                <p className="card-text">
                  <span style={{color: "#fff"}}>Round 1:</span> $42 <br />
                  <span style={{color: "#fff"}}>Round 2:</span> $54 <br />
                  <span style={{color: "#fff"}}>Round 3:</span> $82 <br />
                  <span style={{color: "#fff"}}>Round 4:</span> $110 <br />
                  <span style={{color: "#fff"}}>Round 5:</span> $170 <br />
                  <span style={{color: "#fff"}}>Round 6:</span> $320
                </p>
              </div>
            </div>
          </div>

          {/* BIG Card */}
          <div className="col-md-4 mb-4">
            <div className="card custom-pricing-card text-center" style={{backgroundColor: "#000"}}>
              <div className="card-body">
                <i className="bi bi-emoji-laughing icon-style mb-3"></i>
                <h5 className="card-title">APEX</h5>
                <p className="card-text">
                  <span style={{color: "#fff"}}>Round 1:</span> $200 <br />
                  <span style={{color: "#fff"}}>Round 2:</span> $250 <br />
                  <span style={{color: "#fff"}}>Round 3:</span> $375 <br />
                  <span style={{color: "#fff"}}>Round 4:</span> $500 <br />
                  <span style={{color: "#fff"}}>Round 5:</span> $750 <br />
                  <span style={{color: "#fff"}}>Round 6:</span> $1500
                </p>
              </div>
            </div>
          </div>
        </div>

       
      </div>
    </section>
  );
};

export default PricingSection;
