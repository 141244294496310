import React, { useState, useEffect } from 'react';
import { BiHome, BiShoppingBag, BiInfoCircle, BiBox, BiCustomize, BiPlanet, BiCoin, BiAdjust, BiCoinStack } from 'react-icons/bi';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from './logo.png';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import ConnectButton from './components/ConnectButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import Dashboard from './components/Dashboard';
import Nodes from './components/Nodes';
import Staking from './components/Staking';
import Info from './components/Info';
import WorldInfo from './components/WorldInfo';
import NodeMergeSection from './components/NodeMergeSection';
import FAQs from './components/FAQs';
import Footer from './components/Footer';
import FlipNotification from './components/FlipNotification';
import TokenSale from './components/TokenSale';
import NodesList from './components/NodesList';
import Admin from './components/Admin';
import AdminUsers from './components/AdminUsers';
import AdminUser from './components/AdminUser';
import AdminDistribute from './components/AdminDistribute';
import Marketplace from './components/Marketplace';
import Referral from './components/Referral';
import TxHistory from './components/TxHistory';
import BuyToken from './components/BuyToken';
import BuyTokenNew from './components/BuyTokenNew';
import SETTINGS from './SETTINGS';


function App() {
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [expanded, setExpanded] = useState(false);


    // 1. Add state to hold the referral code
    const [referralCode, setReferralCode] = useState("");

    useEffect(() => {
      // 2. Parse the referral code from the URL query string on component mount
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('referralCode');
      if (code) {
        setReferralCode(code);
      }
    }, []);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  const openUniswap = () => {
    const url = "https://app.uniswap.org/swap?chain=base&inputCurrency=NATIVE&outputCurrency=0x45f368693eee8e3610270f82085784c1c8ac124b"; // Replace with your desired link
    window.open(url, "_blank");
  };

  return (
    <Router>
    {/* 3. Conditionally render a "referral banner" if referralCode is present */}
    {referralCode && (
        <div className="referral-banner">
          <span>Your referral code: <strong style={{color:"#FFFFFF"}}>{referralCode}</strong></span>
          <button
            onClick={() => navigator.clipboard.writeText(referralCode)}
            style={{ marginLeft: '10px' }}
            className='white-btn'
          >
            Copy Code
          </button>
        </div>
      )}
      <Navbar expand="lg" className="top-bar" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={handleLinkClick}>
            <img src={Logo} style={{ width: "90px" }} alt={"Logo"} />
          </Navbar.Brand>
          <div className="d-block d-lg-none">
            <ConnectButton />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : 'expanded')} className='px-0'/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mt-lg-0 mt-4">
              <Nav.Link as={Link} to="/" className="menuBtn" onClick={handleLinkClick}><BiHome /> Home</Nav.Link>
              <NavDropdown title={<span><BiPlanet /> FM NODES</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                <NavDropdown.Item><Nav.Link as={Link} to="/store" onClick={handleLinkClick}>Shop</Nav.Link></NavDropdown.Item>
                {/* <NavDropdown.Item><Nav.Link as={Link} to="/market" onClick={handleLinkClick}>Market</Nav.Link></NavDropdown.Item>*/}
                <NavDropdown.Item><Nav.Link as={Link} to="/list" onClick={handleLinkClick}>My FM NODES</Nav.Link></NavDropdown.Item>
                <NavDropdown.Item><Nav.Link as={Link} to="/craft" onClick={handleLinkClick}>Crafting</Nav.Link></NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={<span><BiCoinStack />Presales</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                {/* <NavDropdown.Item><Nav.Link as={Link} to="/market" onClick={handleLinkClick}>Market</Nav.Link></NavDropdown.Item>*/}
                <NavDropdown.Item><Nav.Link as={Link} to="/presale1" onClick={handleLinkClick}>Presale #1</Nav.Link></NavDropdown.Item>
                <NavDropdown.Item><Nav.Link as={Link} to="/presale2" onClick={handleLinkClick}>Presale #2</Nav.Link></NavDropdown.Item>
              </NavDropdown>

              {/*<Nav.Link as={Link} to="/presale" className="menuBtn" onClick={handleLinkClick}><BiHome /> Buy FMT</Nav.Link>*/}
              <Nav.Link as={Link} to="/staking" className="menuBtn" onClick={handleLinkClick}><BiHome /> Stake FMT</Nav.Link>
              {/*<Nav.Link as={Link}  className="menuBtn" onClick={openUniswap}><BiCoinStack /> Buy on Uniswap</Nav.Link>*/}
              
              
              {isConnected &&
        // Ensure `address` is defined
        address &&
        // Ensure `SETTINGS.owner` is an array
        Array.isArray(SETTINGS?.owner) &&
        // Check if any owner matches the current address
        SETTINGS.owner.some(
          (owner) =>
            typeof owner === 'string' &&
            owner.toLowerCase() === address.toLowerCase()
        ) && (
          <>
            <NavDropdown
              title={
                <span>
                  <BiAdjust /> Admin
                </span>
              }
              id="basic-nav-dropdown"
              className="menuBtn dropdown-padding"
            >
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/admin" onClick={handleLinkClick}>
                  Admin NFTs
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/admin-users" onClick={handleLinkClick}>
                  Admin Users
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  to="/admin-distribute"
                  onClick={handleLinkClick}
                >
                  Admin Distribute
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
          </>
        )}
             </Nav>
          </Navbar.Collapse>
          <div className="d-none d-lg-block" style={{border: "1px solid #453A37", borderRadius: "16px"}}>
            <ConnectButton />
          </div>
        </Container>
      </Navbar>
      <Container className='pb-5'>

        <Routes>
          
          <Route path="/staking" element={<Staking provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/history" element={<TxHistory provider={walletProvider}  />} />
          <Route path="/presale2" element={<BuyToken provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/presale1" element={<BuyTokenNew provider={walletProvider} account={address} isConnected={isConnected} />} />
         
          <Route path="/" element={<Dashboard provider={walletProvider} address={address} isConnected={isConnected} />} />

          {isConnected && SETTINGS.owner.some(owner => owner.toLowerCase() === address.toLowerCase()) && (
            <>
              <Route path="/admin" element={<Admin provider={walletProvider} address={address} isConnected={isConnected} />} />
              <Route path="/admin-distribute" element={<AdminDistribute provider={walletProvider} address={address} isConnected={isConnected} />} />
              <Route path="/admin-users" element={<AdminUsers provider={walletProvider} isConnected={isConnected} />} />
              <Route path="/user/:userAddress" element={<AdminUser provider={walletProvider} isConnected={isConnected} />} />
            </>
          )}

<Route path="/store" element={<Nodes provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/craft" element={<NodeMergeSection provider={walletProvider} account={address} isConnected={isConnected} />} />

          <Route path="/list" element={<NodesList provider={walletProvider} address={address} isConnected={isConnected} />} />
          
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;