
const SETTINGS = {
    nodeContract:"0x1e9fD93A28A7B35afB9bA6f3eFc40A0F32DC29Ad",
    collection: "0xc9A1380eFd62078BDd4A915529c15B39B97d1dc0",
    ///nodeContract:"0xc08b21627A109CBb6BA56cA351b5A6Fe135EcD67",
    //nodeContract:"0xa6A7F2619409bD2F82E152440bE9f1C440c00D64",
    
    tokenSaleAddress:"0x093eF8ad83AbC9e22C7c2c19F17b4cb11c837CF2",
    mainContractNew:"0x7833d02A6d974bEe1B20272e0605cc9c7bBDE3A9",

    mainContract:"0x0d9641D70B6964472bDf6Ce03eED96d98620393d",
    //mainContract:"0xf9f377cb1FBCaE718F6E6b7b4A5bde7f6de0c48A",
    usdtAddress:"0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    tokenAddress:"0x5bA9E0ce610927c1915c9d9de4BdaF2b8A1Dee7e",
    //tokenAddress:"0x14d1996D29616C1fb589522ce6A81a583f1c32bE",
    stakingContractQtech:"0x41618517cd4474938EE0b7ddA183037735f4471E",
    stakingContract:"0xf0a12474F5A77f31b355Fa5DFDb190bD1fF006e0",
    //stakingContract:"0x07875c189656289040f865F49857f123cc74946D",
    //stakingContract:"0x5aAEBb9543725F94785a3945b35319aD275aEc67",
    //stakingContract:"0x84DBe5DdD187eA681E3528BAB49C023Ef05072a2",
    owner: ["0x6974058a1cff527ACB58Ada66f634051A0b0710C","0xbC2C7Fe2ad0E93E5495A5Bf4Ec5A102085c2e34D", "0x2109d357Af9FFbef82a3Cc19eaAFdd654e7E2c79"],
    nativeSymbol: "ETH",
    nativeIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    usdtIcon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    tokenSymbol: "FMT",
    collectionSymbol: "FML",
    openSeaLink: "https://opensea.io/collection/qvrsworlds",
    spatialLink: "https://www.spatial.io/s/QvrseStation-6655c097311b7a21439823eb?share=3953462147396508479",
    dataFetcherContract: "0x2786EcFFd234BCb82E5e1d797e8E8595E64C8fc4",
    marketplaceAddress: "0x7B08167229876E29c656FCEe0fCa75dDcFA143d8",
    
    INFO_WALLETS: [
        {
            address:"0xf1B8B08aC45D6dcFbF03b83B639F8ba7d16a8664",
            name:"Liquidity"
        },
        {
            address:"0x132a0Ff10b797A5A50bF8d28a2bE2d9ED51C2bd2",
            name:"Treasury"
        },
        {
            address:"0x1e9fD93A28A7B35afB9bA6f3eFc40A0F32DC29Ad",
            name:"Nodes"
        },
    ],
    DAILY_TOKENS: 0.345,
    MAX_SUPPLY: 20000,
    AP_PRICE: 4,
    END_TIME: 1843121631 * 1000,
    NEXT_AIRDROP: 1725174000 * 1000,
    NODE_INFO:{
        "1":{
                airdrop_power: 1,
                title: 'CANOPY', 
                level: 'Level 1', 
                dailyRewards: 0.998, 
                refferalPercent: 0, 
                imgUrl: "https://node.fingermonkeys.com/planet.png", 
                animation_web: "https://node.fingermonkeys.com/", 
                craftElements:[],
                price: 25,
                buyCurrency:"USDC"
            },
        "2":{
                airdrop_power: 3,
                title: 'TEMPLE', 
                level: 'Level 2', 
                dailyRewards: 1.996, 
                refferalPercent: 2, 
                imgUrl: "https://node.fingermonkeys.com/planet1.png", 
                animation_web: "https://node1.fingermonkeys.com/", 
                craftElements:["https://node.fingermonkeys.com/planet.png", "https://node.fingermonkeys.com/planet.png"],
                price: 0,
                buyCurrency:""
            },
        "3":{
                airdrop_power: 7,
                title: 'APEX',
                level: 'Level 3',  
                dailyRewards: 9.98,
                refferalPercent: 3, 
                imgUrl: "https://node.fingermonkeys.com/planet1337.png", 
                animation_web: "https://node1337.fingermonkeys.com/", 
                craftElements:["https://node.fingermonkeys.com/planet1.png", "https://node.fingermonkeys.com/planet1.png", "https://node.fingermonkeys.com/planet10.png"],
                price: 0,
                buyCurrency:""
            },
        
            
       
    },
    CRAFT_DICT:{
        "1_1_1_1_1_1_1_1_1_1": ["https://node.fingermonkeys.com/planet1337.png", 3],
        "2_2_2_2_2_0_0_0_0_0": ["https://node.fingermonkeys.com/planet1337.png", 3],
      }


};
export default SETTINGS;
