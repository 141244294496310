import React, { useState, useEffect } from "react";
import { Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import { BrowserProvider, Contract, formatUnits, parseUnits } from "ethers";
import NodesABI from "../abis/NodesABI";
import CollectionABI from "../abis/CollectionABI";
import SETTINGS from "../SETTINGS";
import { ERC20_ABI } from "../abis/erc20";
import { useContract } from "../hooks/useContract";
import logoToken from "../logoToken.svg";
import { useNavigate } from "react-router-dom";
import ConnectButton from "../components/ConnectButton";
import ReferralButton from "../components/ReferralButton";
import SuccessModal from "../components/SuccessModal";
import PricingSection from "../components/PricingSection";
import NodeExtraInfo from "../components/NodeExtraInfo";
import NodePackages from "../components/NodePackages";

/**
 * nodeDetails: each element describes a node type
 *  - name: Display name (CANOPY, TEMPLE, APEX)
 *  - price: Price in USDC (e.g. 110 means 110 USDC)
 *  - daily: Daily QVRS reward
 *  - iframeUrl: 3D model or an external resource link
 */
const nodeDetails = [
  {
    name: "CANOPY",
    price: 22,       // 110 USDC
    daily: 0.998,     // 0.091 QVRS
    iframeUrl: "https://node.fingermonkeys.com/planet.png",
  },
  {
    name: "TEMPLE",
    price: 42,       // 210 USDC
    daily: 1.996,     // 0.182 QVRS
    iframeUrl: "https://node.fingermonkeys.com/planet1.png",
  },
  {
    name: "APEX",
    price: 200,      // 1000 USDC
    daily: 9.98,      // 0.91 QVRS
    iframeUrl: "https://node.fingermonkeys.com/planet1337.png",
  },
];

const Nodes = ({ provider, account, isConnected }) => {
  const [quantities, setQuantities] = useState([1, 1, 1]); // track quantity for each node type
  const { collections, fetchCollections } = useContract(provider);

  // Loading / Transaction states
  const [loading, setLoading] = useState(false);
  const [txMessage, setTxMessage] = useState("");

  // Referral logic
  const [reffCode, setReffCode] = useState("");
  const [reffAddress, setReffAddress] = useState("");
  const [buyAvailable, setBuyAvailable] = useState(false);

  // USDT and QVRS balances/allowances
  const [usdtBalance, setUsdtBalance] = useState("0.00");
  const [usdtAllowance, setUsdtAllowance] = useState(0);
  const [qvrsBalance, setQvrsBalance] = useState("0.00");
  const [qvrsAllowance, setQvrsAllowance] = useState(0);

  // Success Modal
  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState(SETTINGS.NODE_INFO[1].imgUrl);

  const navigate = useNavigate();

  // ----- UTILS -----
  const incrementQuantity = (index) => {
    setQuantities((qts) =>
      qts.map((q, i) => (i === index ? q + 1 : q))
    );
  };
  const decrementQuantity = (index) => {
    setQuantities((qts) =>
      qts.map((q, i) => (i === index && q > 1 ? q - 1 : q))
    );
  };

  // ----- REFERRAL CODE -----
  const _setReffCode = async (code) => {
    setReffCode(code);
    if (code.length <= 3) {
      setBuyAvailable(false);
      return;
    }
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const collectionContract = new Contract(
        SETTINGS.collection,
        CollectionABI,
        signer
      );
      // Check if referral code is valid
      const refferalAddr = await collectionContract.codeUser(code);
      if (
        refferalAddr.toLowerCase() ===
        "0x0000000000000000000000000000000000000000"
      ) {
        setBuyAvailable(false);
      } else {
        // Make sure user is not referring themselves
        if (refferalAddr.toLowerCase() === signer.address.toLowerCase()) {
          alert("You can't refer yourself!");
          setBuyAvailable(false);
        } else {
          setBuyAvailable(true);
        }
      }
      setReffAddress(refferalAddr);
    } catch (err) {
      console.error("Error checking referral code:", err);
      setBuyAvailable(false);
    }
  };

  /**
   * buyNFT: Approve + buy the selected node type using USDC
   * @param {number} index index in nodeDetails array
   */
  const buyNFT = async (index) => {
    try {
      setLoading(true);

      // Prepare
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const node = nodeDetails[index];
      const quantity = quantities[index];

      // USDC has 6 decimals, so multiply final price by 1e6
      const usdcAmount = node.price * quantity * 1e6;

      // 1) Check user balance in USDC
      const usdtContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
      const userBalance = await usdtContract.balanceOf(signer.address);
      if (userBalance < usdcAmount) {
        setTxMessage("Not enough USDC balance for this purchase.");
        alert("Not enough USDC balance for this purchase.");
        return;
      }

      // 2) Check allowance
      const currentAllowance = await usdtContract.allowance(
        signer.address,
        SETTINGS.nodeContract
      );

      if (currentAllowance < usdcAmount) {
        setTxMessage("Approving USDC transfer...");
        const approveTx = await usdtContract.approve(
          SETTINGS.nodeContract,
          usdcAmount.toString()
        );
        await approveTx.wait();
      }

      // 3) Purchase
      setTxMessage("Processing the purchase...");
      const nftContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);

      // E.g. buy(quantity, nodeType, refAddress)
      // If your nodeType is simply 'index + 1':
      const buyTx = await nftContract.buy(quantity, index + 1, reffAddress, "");
      await buyTx.wait();

      setTxMessage("NFT successfully purchased!");
      setModalShow(true);

      // Optionally pick a success image for the purchased node
      setModalImage(SETTINGS.NODE_INFO[index + 1].imgUrl);

      // Refresh data
      await fetchCollections();

    } catch (error) {
      console.error("Error purchasing NFT:", error);
      setTxMessage("Failed to purchase NFT. Please try again.");
    } finally {
      setLoading(false);
      // Hide message after a delay
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  // ----- BALANCES & ALLOWANCES -----
  async function getBalance() {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      // USDT
      const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
      const USDTBalance = await USDTContract.balanceOf(account);
      setUsdtBalance(parseFloat(formatUnits(USDTBalance, 6)).toFixed(2));
      const USDTAllowance = await USDTContract.allowance(
        account,
        SETTINGS.nodeContract
      );
      setUsdtAllowance(parseFloat(USDTAllowance));

      // QVRS
      const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const TokenBalance = await TokenContract.balanceOf(account);
      setQvrsBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));
      const TokenAllowance = await TokenContract.allowance(
        account,
        SETTINGS.nodeContract
      );
      setQvrsAllowance(parseFloat(formatUnits(TokenAllowance, 18)));

      // Check if user has a saved referral
      const NodesContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const savedRefferal = await NodesContract.my_referrer(account);
      if (savedRefferal.toLowerCase() !== "0x0000000000000000000000000000000000000000") {
        setBuyAvailable(true);
        setReffAddress(savedRefferal);
      }
    } catch (error) {
      console.log("error getBalance", error);
    }
  }

  // ----- LIFECYCLE -----
  useEffect(() => {
    if (isConnected) {
      getBalance();
    }
  }, [isConnected]);

  if (loading) {
    return (
      <div className="text-center">
        <br />
        <br />
        <Spinner animation="border" role="status" className="loaderBig" />
        <p className="loaderMsg">{txMessage}</p>
      </div>
    );
  }

  // If you're using 'collections' from a custom hook, you can keep the logic below
  if (collections.length === 0 && isConnected) {
    return (
      <div className="text-center">
        <br />
        <br />
        <Spinner animation="border" role="status" className="loaderBig" />
        <p className="loaderMsg">{txMessage}</p>
      </div>
    );
  }

  return (
    <div className="buy-token-container1 mb-4">
      <h3 className="pageTitle">FM NODES SHOP</h3>
      <p className="round-info pulse-text" style={{color: "#aaa"}}>
        🚀 Hurry! <strong style={{color: "#FFB800"}}>Round 1</strong> is live! Only <strong style={{color: "#FFB800"}}>4,000</strong> Nodes in this round. Don’t miss out!
      </p>
      <Row className="mb-5 pb-md-0 pb-4 pt-md-2 pt-0">
        {nodeDetails.map((node, index) => {
          const quantity = quantities[index];
          const totalPrice = node.price * quantity;  // USDC
          const dailyReward = node.daily * quantity; // QVRS for that many

          return (
            <Col
              xs={12}
              md={6}
              lg={6}
              xl={4}
              className="mb-md-4 mb-3"
              key={index}
            >
              <div className="pokemon-card pokemon-card-height d-flex flex-column justify-content-between">
                <div className="pokemon-card-title pt-0 pb-1">
                  <h3 className="mb-md-0 mb-2 text-center" style={{color: "#fff"}}>{node.name}</h3>
                  <br />
                  <div className="d-flex justify-content-center align-items-center">
               
                    <img
                      src={node.iframeUrl}
                      style={{width:"40%"}}
                      title={`${node.name} Node Iframe`}
                       className="avatar-float"
                    />
                  </div>
                </div>

                {/* QUANTITY SECTION */}
                <div className="pokemon-card-footer" style={{ paddingTop: "6px" }}>
                  <Row className="mb-2">
                    <Col xs={3} sm={2} className="offset-sm-3 offset-2">
                      <Button
                        variant="outline-secondary"
                        className="buton buton-small" style={{width: "36px", backgroundColor: "#111",border: "1px solid #333", color: "#fff"}}
                        onClick={() => decrementQuantity(index)}
                      >
                        -
                      </Button>
                    </Col>
                    <Col xs={2} sm={2}>
                      <div className="pokemon-card-title h5" style={{color: "#fff"}}>{quantity}</div>
                    </Col>
                    <Col xs={3} sm={2}>
                      <Button
                        variant="outline-secondary"
                        className="buton buton-small" style={{width: "36px",backgroundColor: "#111",border: "1px solid #333", color: "#fff"}}
                        onClick={() => incrementQuantity(index)}
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                </div>

                {/* PURCHASE SECTION */}
                {!isConnected ? (
                  <div className="mt-3">
                    <div className="input-group" style={{ marginBottom: "0px" }}>
                      <div className="input-group d-flex justify-content-center align-items-center">
                        <Alert variant="secondary">
                          <div
                            className="py-md-3 py-2 px-md-2 px-1 text-center"
                            style={{ margin: "0px 0px" }}
                          >
                            <h5>Connect wallet to buy {SETTINGS.tokenSymbol} tokens</h5>
                            <br />
                            <ConnectButton />
                          </div>
                        </Alert>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3">
                    {/* Price & Daily Rewards */}
                    <div className="input-group d-flex justify-content-center align-items-center">
                      <p className="mb-0" style={{color: "#fff"}}>Price:</p>
                      <img
                        src={SETTINGS.usdtIcon}
                        style={{
                          width: "18px",
                          marginRight: "8px",
                          marginLeft: "10px",
                          height: "18px",
                        }}
                        alt={"USDC"}
                      />
                      <p className="mb-0 fw-bold">{totalPrice} USDC</p>
                    </div>
                    <div className="input-group d-flex justify-content-center align-items-center">
                      <p className="mb-0" style={{color: "#fff"}}>Daily:</p>
                      <img
                        src={logoToken}
                        style={{
                          width: "18px",
                          marginRight: "8px",
                          marginLeft: "10px",
                          height: "18px",
                        }}
                        alt={"FMT"}
                      />
                      <p className="mb-0 fw-bold">
                        {dailyReward.toFixed(3)} FMT
                      </p>
                    </div>

                    {buyAvailable ? (
                      <div>
                        <Button
                          variant="primary"
                          onClick={() => buyNFT(index)}
                          className="buton mb-2 mt-0"
                        >
                          BUY FM NODE
                        </Button>
                        <center>
                          <span
                            style={{
                              marginTop: "6px",
                              display: "block",
                              fontWeight: "500",
                            }}
                          >
                            <small style={{color: "#17B502"}}>Your balance:</small>
                            <img
                              src={SETTINGS.usdtIcon}
                              className="tokenIconColor"
                              style={{
                                width: "18px",
                                marginRight: "5px",
                                marginLeft: "8px",
                                marginTop: "-2px",
                              }}
                              alt={"USDC"}
                            />
                            <small className="fw-bold">
                              {new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(usdtBalance)}{" "}
                              USDC
                            </small>
                          </span>
                        </center>
                      </div>
                    ) : (
                      <div style={{ position: "relative" }}>
                        <div className="input-group" style={{ marginBottom: "10px" }}>
                          <input
                            type="text"
                            value={reffCode}
                            disabled={!isConnected}
                            onChange={(e) => _setReffCode(e.target.value)}
                            placeholder="Enter Referral Code Here"
                            className="custom-select refcode" style={{backgroundColor: "#000"}}
                          />
                        </div>
                        <ReferralButton />
                        <center>
                          <span
                            style={{
                              marginTop: "0px",
                              display: "block",
                              fontWeight: "500",
                            }}
                          >
                            <small style={{color: "#17B502"}}>Your balance:</small>
                            <img
                              src={SETTINGS.usdtIcon}
                              className="tokenIconColor"
                              style={{
                                width: "18px",
                                marginRight: "5px",
                                marginLeft: "8px",
                                marginTop: "-2px",
                              }}
                              alt={"USDC"}
                            />
                            <small className="fw-bold">
                              {new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(usdtBalance)}{" "}
                              USDC
                            </small>
                          </span>
                        </center>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
<PricingSection />
<NodePackages />
<NodeExtraInfo />


      {/* SUCCESS MODAL */}
      <SuccessModal
        show={modalShow}
        displayImage={modalImage}
        onHide={() => setModalShow(false)}
      />

      {/* Transaction status (optional) */}
      {txMessage && (
        <p className="text-center fw-bold" style={{ color: "#555" }}>
          {txMessage}
        </p>
      )}
    </div>
  );
};

export default Nodes;
