import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import copyImg from "../copy.png";
import StripeLoaderImg from "../stripe_loader.svg";
import CollectionABI from "../abis/CollectionABI";
// Import the QvrseNodes ABI
import QvrseNodesABI from "../abis/NodesABI.json";
import SETTINGS from "../SETTINGS";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import { useNavigate } from "react-router-dom";
import { BiLike } from "react-icons/bi";

const RefferalComponent = ({ provider, isConnected }) => {
  const [loading, setLoading] = useState(true);
  const [copying, setCopying] = useState(false);

  // Local states
  const [refferalCode, setRefferalCode] = useState("");
  const [userHasNode, setUserHasNode] = useState(false);
  const [userPercent, setUserPercent] = useState(8);
  const [userCode, setUserCode] = useState("");

  // Referral Balances
  const [referralBalance, setReferralBalance] = useState("0");

  // For buying with referral (modal):
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [selectedNodeType, setSelectedNodeType] = useState("1");
  const [buyQuantity, setBuyQuantity] = useState("1");

  const navigate = useNavigate();

  // Helper to set referral code
  const _setReffCode = async (code) => {
    setRefferalCode(code);
  };

  // Copy code to clipboard
  const copyCode = () => {
    setCopying(true);
    //navigator.clipboard.writeText(userCode);
    navigator.clipboard.writeText("https://dapp.fingermonkeys.com/store?referralCode=" + userCode);
    setTimeout(() => setCopying(false), 2000);
  };

  // Create user referral code in the Collection contract
  const createCode = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      setLoading(true);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);

      if (refferalCode.length <= 5) {
        alert("Referral code should be longer than 5 characters.");
        setLoading(false);
        return;
      }

      // Check if code is already taken
      const codeCheck = await collectionContract.codeUser(refferalCode.toUpperCase());
      if (codeCheck !== "0x0000000000000000000000000000000000000000") {
        alert("That referral code is already taken.");
        setLoading(false);
        return;
      }

      // Attempt to create the referral code
      const refTx = await collectionContract.createReferralCode(refferalCode.toUpperCase());
      await refTx.wait();

      // Refresh user code in UI
      const existingCode = await collectionContract.referralCode(signer.address);
      setUserCode(existingCode);
    } catch (error) {
      console.error("Error creating referral code:", error);
    } finally {
      setTimeout(() => setLoading(false), 2000);
    }
  };

  // Check if user has any node in the Collection
  const checkUserHasNode = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);

      // Query the balance of NFTs for this user
      const balance = await collectionContract.balanceOf(signer.address);
      setUserHasNode(balance > 0);

      // If user already created a code, fetch it:
      const existingCode = await collectionContract.referralCode(signer.address);
      setUserCode(existingCode);
    } catch (error) {
      console.error("Error checking user nodes:", error);
    } finally {
      setLoading(false);
    }
  };


  
  const fetchReferralBalance = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      console.log(signer.address);
  
      const qvrseContract = new Contract(SETTINGS.nodeContract, QvrseNodesABI, signer);
  
      // This returns a BigNumber representing a 6-decimal USDC-like balance
      const bal = await qvrseContract.referralBalances(signer.address);
  
      // Format the BigNumber with 6 decimals, then parseFloat for a clean numeric string
      const formattedBalance = parseFloat(formatUnits(bal, 6)).toFixed(2);
  
      setReferralBalance(formattedBalance);
    } catch (error) {
      console.error("Error fetching referral balance:", error);
    } finally {
      setLoading(false);
    }
  };
  

  // Claim referral balance from QvrseNodes (in USDC)
  const claimReferralRewards = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const qvrseContract = new Contract(SETTINGS.nodeContract, QvrseNodesABI, signer);

      const tx = await qvrseContract.claimReferralRewards();
      await tx.wait();

      // Refresh referral balance
      await fetchReferralBalance();
      alert("Referral balance claimed successfully!");
    } catch (error) {
      console.error("Error claiming referral balance:", error);
    } finally {
      setLoading(false);
    }
  };

  // Buy a node using referral balance
  const buyWithReferralRewards = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const qvrseContract = new Contract(SETTINGS.nodeContract, QvrseNodesABI, signer);

      // Convert quantity to a number
      const quantityNum = parseInt(buyQuantity, 10);
      const tokenTypeNum = parseInt(selectedNodeType, 10);

      if (quantityNum < 1) {
        alert("Quantity must be at least 1");
        setLoading(false);
        return;
      }

      // Call buy function in QvrseNodes
      const tx = await qvrseContract.buyWithReferralRewards(quantityNum, tokenTypeNum);
      await tx.wait();

      // Refresh referral balance
      await fetchReferralBalance();
      setShowBuyModal(false);
      alert(`You bought ${quantityNum} of type-${tokenTypeNum} using referral rewards!`);
    } catch (error) {
      console.error("Error buying node with referral rewards:", error);
    } finally {
      setLoading(false);
    }
  };

  // On mount or when 'isConnected' changes, do our checks
  useEffect(() => {
    if (isConnected) {
      checkUserHasNode();
      fetchReferralBalance();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, provider]);

  if (loading) {
    return (
      <div className="pokemon-card">
        <div className="text-center">
          <center>
            <img src={StripeLoaderImg} style={{ width: "40%" }} alt="Loading..." />
          </center>
        </div>
      </div>
    );
  }

  return (
    <div className="text-center mb-2">
 

      {/* ============================
          2) REFERRAL BALANCE SECTION
      ============================ */}
      <div className="my-4">
        <h5 style={{color: "#fff"}}>Your Referral Balance</h5>
        <p>
          <strong>{referralBalance}</strong> USDC
        </p>

        {/* Buttons in one line */}
        <div className="d-flex justify-content-center align-items-center">
          {/* Claim Button */}
          <Button
            variant="primary"
            className="me-2 buton buton-secondary"
           
            onClick={claimReferralRewards}
          >
            Claim Rewards
          </Button>

          {/* Open Modal Button */}
          <Button
            variant="primary"
            className="buton buton-secondary"
            onClick={() => setShowBuyModal(true)}
          >
            Buy with Rewards
          </Button>
        </div>
      </div>

           {/* ==============================
          1) REFERRAL CODE CREATION UI
      ============================== */}
      {!userHasNode ? (
        // If user has 0 nodes, they cannot create a referral code
        <div className="pokemon-card">
          <div className="text-center" style={{ margin: "0px 0px" }}>
            <center>
              <p className="small mb-0">
                You must own at least one node to create a referral code.
              </p>
            </center>
          </div>
        </div>
      ) : (
        <>
          {/* If user owns at least 1 node, show create referral code UI */}
          {userCode === "" ? (
            <div>
              <div className="input-group" style={{ marginBottom: "14px" }}>
                <label className="mb-1">Create referral code</label>
                <input
                  type="text"
                  value={refferalCode}
                  onChange={(e) => _setReffCode(e.target.value)}
                  placeholder="Referral Code"
                  className="custom-select"
                />
              </div>
              <Button
                className="buton buton-secondary d-lg-block"
                onClick={createCode}
              >
                CREATE
              </Button>
            </div>
          ) : (
            <>
              <p className="small mt-2">
                ** Invite others and get rewarded when they purchase a FM NODE!
              </p>
              <h6 className="mt-1" style={{color: "#fff"}}>Referral percent: <span style={{color: "#aaa"}}>{userPercent}%</span></h6>
              {copying ? (
                <Button
                  variant="primary mt-3 d-dlex justify-content-center align-items-center"
                  className="buton"
                >
                  <BiLike style={{ fontSize: "20px" }} />
                </Button>
              ) : (
                <Button variant="primary mt-3" onClick={copyCode} className="buton">
                  <img
                    src={copyImg}
                    style={{ width: "13px", marginRight: "5px", opacity: "1" }}
                    alt="Copy"
                  />
                  {userCode}
                </Button>
              )}
            </>
          )}
        </>
      )}

      {/* =============
          MODAL for Buying
         ============= */}
      <Modal
        show={showBuyModal}
        onHide={() => setShowBuyModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color: "#fff"}}>Buy Nodes with Referral Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Node Type Dropdown (1,2,3) */}
          <div className="mb-3 input-group">
            <label style={{color: "#aaa", marginRight: "8px" }}>Node Type:</label>
            <select
              value={selectedNodeType}
              onChange={(e) => setSelectedNodeType(e.target.value)}
              className="custom-select mt-2" style={{border: "1px solid #333"}}
            >
              <option value="1">CANOPY</option>
              <option value="2">TEMPLE</option>
              <option value="3">APEX</option>
            </select>
          </div>

          {/* Quantity Input */}
          <div className="mb-3 input-group">
            <label style={{color: "#aaa", marginRight: "8px" }}>Quantity:</label>
            <input
              type="number"
              min="1"
              value={buyQuantity}
              onChange={(e) => setBuyQuantity(e.target.value)}
              className="custom-select mt-2" 
              style={{border: "1px solid #333", width: "100%"}}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" className="buton" onClick={buyWithReferralRewards}>
            Buy FM NODE
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RefferalComponent;
