import React from "react";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="container text-center">
        <h1 className="hero-title">

          <span className="highlight">FM NODES</span>
        </h1>
     
        <p className="hero-subtitle">
          A revolutionary way to invest through NFT Liquidity Nodes.
          <br />
          Buy <strong style={{color: "#FFB800"}}>CANOPY</strong>, <strong style={{color: "#FFB800"}}>TEMPLE</strong>, or{" "}
          <strong style={{color: "#FFB800"}}>APEX</strong> nodes, earn rewards, and enjoy
          referral commissions.
        </p>
     
      </div>
    </section>
  );
};

export default HeroSection;
