import React from "react";

const Distribution = () => {
  return (
    <section id="distribution" className="py-5 mt-5" style={{border: "1px solid #453A37",borderRadius: "10px", backgroundColor: "#111"}}>
      <div className="container">
        <h2 className="section-title">Rewards &amp; Distribution</h2>
        <div className="row mt-4 text-center ">
          <div className="col-md-6 mb-2">
            <h4>Daily FMT Tokens</h4>
            <p>
              Each node generates FMT tokens daily over up to 1460 days (4
              years). APEX nodes earn ~9.98 FMT/day (14583 total), while CANOPY
              and TEMPLE earn proportionally less.
            </p>
          </div>
          <div className="col-md-6 mb-2">
            <h4>USDC Distribution for APEX Nodes</h4>
            <p>
              Periodically, the project adds USDC to a reward pool exclusively
              for APEX nodes. Each APEX node can claim its share anytime.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Distribution;
