import React from "react";
import { BiBrush, BiDockTop, BiGroup, BiPlanet, BiRocket } from "react-icons/bi";

const HowItWorks = () => {
  return (
    <section id="how-it-works" className="py-5 how-it-works-section mt-5" style={{border: "1px solid #453A37"}}>
      <div className="container">
        <h2 className="section-title text-center mb-4">How FM NODES Work</h2>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="step-box text-center" style={{backgroundColor: "#000"}}>
              <div className="step-icon mb-3">
                <BiPlanet className="bi bi-gear" />
              </div>
              <h4 className="step-title">1. Choose Your FM NODE</h4>
              <p className="step-text" style={{color: "#aaa"}}>
                Pick from <strong style={{color: "#FFB800"}}>CANOPY</strong>, <strong style={{color: "#FFB800"}}>TEMPLE</strong>, or{" "}
                <strong style={{color: "#FFB800"}}>APEX</strong> nodes during multiple rounds of increasing
                prices.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="step-box text-center" style={{backgroundColor: "#000"}}>
              <div className="step-icon mb-3">
                <BiRocket className="bi bi-fire" />
              </div>
              <h4 className="step-title">2. Craft to Grow</h4>
              <p className="step-text" style={{color: "#aaa"}}>
                Burn 10 CANOPY or 5 TEMPLE nodes to craft a APEX node.
                APEX node gives the best rewards.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="step-box text-center" style={{backgroundColor: "#000"}}>
              <div className="step-icon mb-3">
                <BiGroup className="bi bi-people" />
              </div>
              <h4 className="step-title">3. Earn &amp; Refer</h4>
              <p className="step-text" style={{color: "#aaa"}}>
                Earn FMT tokens and USDC distributions. Refer friends to
                boost your income!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
