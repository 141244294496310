import React, { useState, useEffect } from "react";
import { BiPlanet, BiCustomize } from "react-icons/bi";
import { Button, Col, Row } from "react-bootstrap";
import StripeLoaderImg from "../stripe_loader.svg";
import CollectionABI from "../abis/CollectionABI";
import SETTINGS from "../SETTINGS";
import { BrowserProvider, Contract } from "ethers";
import { useNavigate } from "react-router-dom";

const LevelComponent = ({ provider, isConnected }) => {
  const [loading, setLoading] = useState(true);
  const [userLevel, setUserLevel] = useState(1);
  const [levelModelUrl, setLevelModelUrl] = useState("https://node.fingermonkeys.com/planet.png");

  // Track how many of each node type the user owns (we have only 1,2,3)
  const [nodeCounts, setNodeCounts] = useState({ 1: 0, 2: 0, 3: 0 });

  const navigate = useNavigate();

  const openCraft = () => {
    navigate("/craft");
  };
  const openBuyNode = () => {
    navigate("/store");
  };

  const getUserLevel = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);

      const balance = await collectionContract.balanceOf(signer.address);
      let maxType = 0;

      // Create a local copy of nodeCounts
      let localCounts = { 1: 0, 2: 0, 3: 0 };

      for (let i = 0; i < balance; i++) {
        const tokenId = await collectionContract.tokenOfOwnerByIndex(signer.address, i);
        const tokenType = parseInt(await collectionContract.tokenTypes(tokenId));

        // If it's one of our 3 known node types, increment the count
        if ([1, 2, 3].includes(tokenType)) {
          localCounts[tokenType] += 1;
        }

        // Track the highest token type
        if (tokenType > maxType) {
          maxType = tokenType;
        }
      }

      // Update state
      setNodeCounts(localCounts);
      setUserLevel(maxType);

      // Adjust the 3D model URL based on the highest node type
      if (maxType === 1) {
        setLevelModelUrl("https://node.fingermonkeys.com/planet.png"); // Or whatever 3D model for type-1
      } else if (maxType === 2) {
        setLevelModelUrl("https://node.fingermonkeys.com/planet1.png/"); // Type-2 3D model
      } else if (maxType === 3) {
        setLevelModelUrl("https://node.fingermonkeys.com/planet1337.png"); // Type-3 3D model
      } else {
        // If user has 0 or something else (shouldn't happen if only 1/2/3 exist)
        setLevelModelUrl("https://node.fingermonkeys.com/planet.png");
      }
    } catch (error) {
      console.error("Error fetching user level:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isConnected) {
      getUserLevel();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, provider]);

  if (loading) {
    return (
      <div className="pokemon-card">
        <div className="text-center">
          <center>
            <img src={StripeLoaderImg} alt="Loading..." />
          </center>
        </div>
      </div>
    );
  }

  return (
    <div>
   

      {/* The iframe container showing the highest node's model */}
      <Row className="align-items-center">
        <Col xs={6} md={12}>
          <div className="iframe-container">
            <img style={{width:"40%"}} src={levelModelUrl} title="Node 3D View" className="avatar-float" />
          </div>
   
        </Col>

        {/* Buttons for Mobile */}
        <Col xs={6} md={12} className="d-md-none d-flex">
          <Row className="mb-4">
            <Col xs={12}>
              <Button
                variant="primary buton mt-3 d-flex align-items-center justify-content-center"
                onClick={openBuyNode}
                className="buton"
              >
                <BiPlanet className="me-1" /> SHOP
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                variant="primary buton mt-2 d-flex align-items-center justify-content-center"
                onClick={openCraft}
                className="buton"
              >
                <BiCustomize className="me-1" /> CRAFT
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Buttons for Desktop */}
      <Col xs={6} md={12} className="d-md-block d-none mt-4 pt-1">
        <Row className="mb-3">
          <Col>
            <Button
              variant="primary buton mt-2 d-flex align-items-center justify-content-center"
              onClick={openBuyNode}
              className="buton"
            >
              <BiPlanet className="me-1" /> SHOP
            </Button>
          </Col>
          <Col>
            <Button
              variant="primary buton mt-2 d-flex align-items-center justify-content-center"
              onClick={openCraft}
              className="buton"
              style={{ height: "42.5px", fontSize: "15px" }}
            >
              <BiCustomize className="me-1" /> CRAFT
            </Button>
          </Col>
        </Row>
      </Col>
                {/* Row to display how many of each node type */}
                <Row className="my-3 text-center">
        {/* Type-1 */}
        <Col>
          <img
            src="https://node.fingermonkeys.com/planet.png"
            alt="Type1"
            style={{ width: "30px", marginRight: "5px" }}
          />
          <span className="smallAmount" style={{color: "#fff"}}>{nodeCounts[1]}</span>
        </Col>

        {/* Type-2 */}
        <Col>
          <img
            src="https://node.fingermonkeys.com/planet1.png"
            alt="Type2"
            style={{ width: "30px", marginRight: "5px" }}
          />
          <span className="smallAmount" style={{color: "#fff"}}>{nodeCounts[2]}</span>
        </Col>

        {/* Type-3 */}
        <Col>
          <img
            src="https://node.fingermonkeys.com/planet1337.png"
            alt="Type3"
            style={{ width: "30px", marginRight: "5px" }}
          />
          <span className="smallAmount" style={{color: "#fff"}}>{nodeCounts[3]}</span>
        </Col>
      </Row>
    </div>
  );
};

export default LevelComponent;
