import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SETTINGS from "./SETTINGS";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId
const projectId = '3c505f0b5c4d9568885404904e7e6d18'



const mainnet = {
  chainId: 8453,
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org/',
  rpcUrl: 'https://mainnet.base.org'
}

// 3. Create a metadata object
const metadata = {
  name: 'Finger Monkeys',
  description: 'Finger Monkeys dapp',
  url: 'https://dapp.fingermonkeys.com/', // origin must match your domain & subdomain
  icons: ['https://dapp.fingermonkeys.com/logo192.png']
}


// 5. Create a Web3Modal instance
const web3Modal = createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    //enableEmail: true,
    enableEIP6963: true,
  }),
  chains: [mainnet],
  projectId,
  themeMode: 'dark',
  themeVariables: {
    '--w3m-accent': '#18b502'
  },
  enableOnramp: true,

  enableAnalytics: true,
})

console.log('Web3Modal Initialized:', web3Modal);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
