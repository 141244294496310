import React, { useEffect, useState } from 'react';
import { BrowserProvider, Contract } from 'ethers';
import NodesABI from '../abis/NodesABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Spinner, Alert } from 'react-bootstrap';
import MergeContainer from './MergeContainer';
import DraggableNode from './DraggableNode';
import NodeExtraInfo from './NodeExtraInfo';

import CraftBook from './CraftBook';

const NodeMergeSection = ({ provider, address, isConnected }) => {
  const [ownedNFTs, setOwnedNFTs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [node1, setNode1] = useState(null);
  const [node2, setNode2] = useState(null);
  const [node3, setNode3] = useState(null);
  const [node4, setNode4] = useState(null);
  const [node5, setNode5] = useState(null);
  const [node6, setNode6] = useState(null);
  const [node7, setNode7] = useState(null);
  const [node8, setNode8] = useState(null);
  const [node9, setNode9] = useState(null);
  const [node10, setNode10] = useState(null);

  const hideCard = (tokenId) => {
    let nftsArr = [];
    for (let i = 0; i < ownedNFTs.length; i++) {
      let nft = ownedNFTs[i];
      if (nft.tokenId === tokenId) {
        nft.visible = false;
      }
      nftsArr.push(nft);
    }
    setOwnedNFTs(nftsArr);
  };



  const fetchOwnedNFTs = async () => {
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);
      const account = await signer.getAddress();
      const nftsForUser = await contract.getAllNFTsForUser(account);

      const serialized = JSON.stringify(nftsForUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const nfts = JSON.parse(serialized);

      // Fetch all NFT data in parallel
      const nftDataPromises = nfts.map(async (nft) => {
        const response = await fetch(nft[3]);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        const tokenType = await collectionContract.tokenTypes(parseInt(nft[1]));

        return {
          collectionAddress: nft[0],
          tokenId: nft[1],
          name: nft[2],
          imageURL: json.image,
          tokenType: tokenType.toString(),
          visible: true
        };
      });

      const nftsArr = await Promise.all(nftDataPromises);

      setOwnedNFTs(nftsArr);
     
    } catch (e) {
      console.log("owned nfts error");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };


  const handleNodeClick = (nft) => {
    if (!node1) {
      setNode1(nft);
      hideCard(nft.tokenId);
    } else if (!node2) {
      setNode2(nft);
      hideCard(nft.tokenId);
    } else if (!node3) {
      setNode3(nft);
      hideCard(nft.tokenId);
    } else if (!node4) {
      setNode4(nft);
      hideCard(nft.tokenId);
    } else if (!node5) {
      setNode5(nft);
      hideCard(nft.tokenId);
    }else if (!node6) {
      setNode6(nft);
      hideCard(nft.tokenId);
    }else if (!node7) {
      setNode7(nft);
      hideCard(nft.tokenId);
    }else if (!node8) {
      setNode8(nft);
      hideCard(nft.tokenId);
    }else if (!node9) {
      setNode9(nft);
      hideCard(nft.tokenId);
    }else if (!node10) {
      setNode10(nft);
      hideCard(nft.tokenId);
    }
  };

  useEffect(() => {
    if (!provider) return;
    fetchOwnedNFTs();
  }, [provider]);

  return (
    <div>
      <h3 className='pageTitle mb-4'>Crafting</h3>
     
      <Row className='mt-3'>
        <Col xs={12} sm={12} md={10} lg={8} xl={6} className="offset-xl-0 offset-lg-2 offset-md-1 offset-0 mt-4">
          <MergeContainer
            provider={provider}
            address={address}
            ownedNFTs={ownedNFTs}
            fetchOwnedNFTs={fetchOwnedNFTs}
            hideCard={hideCard}
            node1={node1}
            node2={node2}
            node3={node3}
            node4={node4}
            node5={node5}
            node6={node6}
            node7={node7}
            node8={node8}
            node9={node9}
            node10={node10}
            setNode1={setNode1}
            setNode2={setNode2}
            setNode3={setNode3}
            setNode4={setNode4}
            setNode5={setNode5}
            setNode6={setNode6}
            setNode7={setNode7}
            setNode8={setNode8}
            setNode9={setNode9}
            setNode10={setNode10}
          />
        </Col>
        <Col xs={12} sm={12} md={10} lg={8} xl={6} className="mb-4 offset-xl-0 offset-lg-2 offset-md-1 offset-0 mt-4">
          {loading ? (
            <center>
              <Spinner animation="border" role="status" className='loaderBig mt-5' />
            </center>
          ) : (
            <Row>
              <Col xs={12}>
                <Alert variant="primary" style={{background: "#191915", color: "#fff", border: "1px solid #D72600"}}>
                  <div className='fw-bold'>
                    <small>Press and hold to drag or click to add to the first empty slot.</small>
                  </div>
                </Alert>
              </Col>
              {ownedNFTs.map((nft, index) => (
                <Col xs={4} sm={4} md={3} lg={4} xl={3} key={index} style={{ display: nft.visible ? "" : "none" }}>
                  <DraggableNode
                    key={index}
                    nft={nft}
                    onClick={handleNodeClick}
                  />
                </Col>
              ))}
            </Row>
          )}
        </Col>
      </Row>

     <NodeExtraInfo />

    </div>
  );
};

export default NodeMergeSection;
