import React, { useState, useEffect } from "react";
import { Card, Button, Form, Spinner, Alert, Row, Col } from "react-bootstrap";
import {
  BrowserProvider,
  Contract,
  parseUnits,
  formatUnits
} from "ethers";
import NodesABI from "../abis/NodesABI.json";
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";

function AdminDistribute({ provider, isConnected }) {
  // Existing states
  const [depositAmount, setDepositAmount] = useState("");
  const [approving, setApproving] = useState(false);
  const [depositing, setDepositing] = useState(false);
  const [txMessage, setTxMessage] = useState("");

  // NEW: State to store wallet balances
  const [walletBalances, setWalletBalances] = useState([]);
  const [loadingBalances, setLoadingBalances] = useState(false);


  const [allowance, setAllowance] = useState("0");
  const [loadingAllowance, setLoadingAllowance] = useState(false);

  // Fetch USDC allowance whenever the wallet connects
  useEffect(() => {
    if (isConnected) {
      fetchAllowance();
    }
  }, [isConnected]);

  // Get how much USDC is already approved to QvrseNodes
  const fetchAllowance = async () => {
    try {
      setLoadingAllowance(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const usdcContract = new Contract(
        SETTINGS.usdtAddress, // or USDC address
        ERC20_ABI,
        signer
      );

      const userAddress = await signer.getAddress();
      const allowanceValue = await usdcContract.allowance(
        userAddress,
        SETTINGS.nodeContract
      );

      // USDC typically has 6 decimals
      setAllowance(formatUnits(allowanceValue, 6));
    } catch (error) {
      console.error("Error fetching allowance:", error);
    } finally {
      setLoadingAllowance(false);
    }
  };

  // Fetch balances whenever wallet connects
  useEffect(() => {
    if (isConnected) {
      fetchWalletBalances();
    }
  }, [isConnected]);

  // ------------------------------------------------------------------
  // 1) Fetch USDC & QVRS balances for each wallet in SETTINGS.INFO_WALLETS
  // ------------------------------------------------------------------
  const fetchWalletBalances = async () => {
    try {
      setLoadingBalances(true);

      // You can use either a read-only provider or the signer
      const ethersProvider = new BrowserProvider(provider);
      const usdcContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, ethersProvider);
      // If you have a QVRS token contract address in SETTINGS, do the same:
      const qvrsContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, ethersProvider);

      const newBalances = [];
      for (const w of SETTINGS.INFO_WALLETS) {
        // Fetch USDC balance (6 decimals)
        const rawUsdcBal = await usdcContract.balanceOf(w.address);
        const usdcBal = formatUnits(rawUsdcBal, 6);

        // Fetch QVRS balance (18 decimals)
        const rawQvrsBal = await qvrsContract.balanceOf(w.address);
        const qvrsBal = formatUnits(rawQvrsBal, 18);

        newBalances.push({
          ...w,
          usdcBal,
          qvrsBal,
        });
      }
      setWalletBalances(newBalances);
    } catch (error) {
      console.error("Error fetching wallet balances:", error);
    } finally {
      setLoadingBalances(false);
    }
  };

  // ------------------------------------------------------------------
  // 2) Approve the QvrseNodes contract to pull 'depositAmount' USDC
  // ------------------------------------------------------------------
  const handleApprove = async () => {
    try {
      setApproving(true);
      setTxMessage("");

      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const usdcContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);

      const amountToApprove = parseUnits(depositAmount, 6);

      const tx = await usdcContract.approve(SETTINGS.nodeContract, amountToApprove);
      setTxMessage("Approving USDC...");
      await tx.wait();

      setTxMessage("USDC approved successfully!");
      // Optionally re-fetch allowances here if you’re displaying that info
    } catch (error) {
      console.error("Error approving USDC:", error);
      setTxMessage("Error approving USDC. Check console for details.");
    } finally {
      setApproving(false);
    }
  };

  // ------------------------------------------------------------------
  // 3) Deposit USDC into QvrseNodes for type-3 distribution
  // ------------------------------------------------------------------
  const handleDeposit = async () => {
    try {
      setDepositing(true);
      setTxMessage("");

      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const nodesContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const amountToDeposit = parseUnits(depositAmount, 6);

      const tx = await nodesContract.depositUsdcForType3(amountToDeposit);
      setTxMessage("Depositing USDC for type-3 nodes...");
      await tx.wait();

      setTxMessage("USDC deposited successfully!");
    } catch (error) {
      console.error("Error depositing USDC:", error);
      setTxMessage("Error depositing USDC. Check console for details.");
    } finally {
      setDepositing(false);
    }
  };

  // ------------------------------------------------------------------
  // Render
  // ------------------------------------------------------------------
  return (
    <div className="adminDistributeContainer">

      {/* MAIN CARD for Approve/Deposit */}
      <Card className="mb-5 mt-5" style={{border: "1px solid #453A37",color: "#aaa",backgroundColor: "#000"}}>
        <Card.Body>
          <Card.Title style={{color: "#fff"}}>Admin Distribute USDC</Card.Title>

          {!isConnected ? (
            <Alert variant="warning">
              Please connect your wallet to proceed.
            </Alert>
          ) : (
            <>
            <Form.Group className="mb-3">
              <Form.Label>Current USDC Allowance</Form.Label>
              {loadingAllowance ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <Alert variant="info" className="p-2">
                  {allowance} USDC
                </Alert>
              )}
            </Form.Group>
              <Form.Group controlId="depositAmount" className="mb-3">
                <Form.Label>USDC Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter amount of USDC"
                  value={depositAmount}
                  onChange={(e) => setDepositAmount(e.target.value)}
                />
              </Form.Group>

              <div className="d-flex gap-2 mb-3">
                <Button
                  variant="primary"
                  onClick={handleApprove}
                  disabled={approving || depositing || !depositAmount}
                style={{border: "0px solid #fff"}}>
                  {approving ? <Spinner animation="border" size="sm" /> : "Approve USDC"}
                </Button>

                <Button
                  variant="primary"
                  onClick={handleDeposit}
                  disabled={approving || depositing || !depositAmount} style={{border: "0px solid #fff"}}
                >
                  {depositing ? <Spinner animation="border" size="sm" /> : "Deposit USDC"}
                </Button>
              </div>
            </>
          )}

          {txMessage && <Alert variant="info">{txMessage}</Alert>}
        </Card.Body>
      </Card>

      {/* CARDS FOR EACH WALLET IN SETTINGS.INFO_WALLETS */}
      <Row>
        {loadingBalances ? (
          <Col>
            <Spinner animation="border" />
            <span className="ms-2">Loading wallet balances...</span>
          </Col>
        ) : (
          walletBalances.map((wallet) => (
            <Col key={wallet.address} xs={12} md={6} lg={4} className="mb-3">
              <Card style={{backgroundColor: "#000",border: "1px solid #453A37"}}>
                <Card.Body>
                  <Card.Title style={{color: "#fff"}}>{wallet.name}</Card.Title>
                  <Card.Text>
                    <strong>Address:</strong><br />
                    <small>{wallet.address}</small>
                  </Card.Text>
                  <Card.Text>
                    <strong>USDC Balance:</strong> {wallet.usdcBal}
                    <br />
                    <strong>FMT Balance:</strong> {wallet.qvrsBal}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </div>
  );
}

export default AdminDistribute;
