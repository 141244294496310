import React, { useEffect, useState } from 'react';
import parachuteImg from '../parachute.png';
import logoToken from '../logoToken.svg';
import { useContract } from '../hooks/useContract';
import QtechStakingABI from '../abis/QtechStakingABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import NodesABI from '../abis/NodesABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import CardNode from '../components/CardNode';
import NodeExtraInfo from "../components/NodeExtraInfo";
import { useNavigate } from 'react-router-dom';

function NodesList({ provider, address, isConnected }) {
  const { ownedNFTs, totalRewards, ownedNFTsLoaded, unclaimedUsdc, totalDailyRewards, distinctElements, NFTTypeCount, ownedQworlds } = useContract(provider);
  const [usdtBalance, setUsdtBalance] = useState('0.00');
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const navigate = useNavigate();
  const [userLevel, setUserLevel] = useState(1);
  const trunTokenAddress = `${SETTINGS.tokenAddress.slice(0, 4)}...${SETTINGS.tokenAddress.slice(-4)}`;
  const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-4)}`;

  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(
      SETTINGS.collection,
      CollectionABI,
      signer
    );
    const level = await collectionContract.levels(signer.address);
    if (parseInt(level) > 1) {
      setUserLevel(parseInt(level));
    }
  };

  async function getBalance() {
    if (!isConnected) setLoadingBalance(true);
    try {
      await getUserLevel();
      // ... any other balance updates if needed
    } catch (error) {
      console.error("Error getting balance:", error);
    }
    setLoadingBalance(false);
  }

  const openBuyToken = () => {
    navigate('/token');
  };
  const openStakeToken = () => {
    navigate('/staking');
  };
  const openBuyNode = () => {
    navigate('/qvrs-worlds');
  };
  const openFAQs = () => {
    navigate('/FAQs');
  };

 

  // New function to claim all token rewards.
  async function claimRewards() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      setLoading(true);
      setTxMessage("Claiming token rewards...");
      // Assuming the claimAllTokenRewards() function is part of the contract at SETTINGS.collection
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const tx = await contract.claimAllTokenRewards();
      await tx.wait();
      setTxMessage("Token rewards claimed successfully!");
      await getBalance();
    } catch (error) {
      console.error("Error claiming rewards:", error);
      setTxMessage("Failed to claim rewards. Please try again.");
    } finally {
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  }

  useEffect(() => {
    if (isConnected) {
      getBalance();
      // Optionally, load stakes or other info.
    } else {
      setQvrsStakes([]);
      setTokenBalance("0.00");
      setUserLevel(1);
    }
  }, [isConnected, provider]);

  if (loading) {
    return (
      <div className="text-center">
        <br />
        <br />
        <Spinner animation="border" role="status" className="loaderBig" />
        <p className="loaderMsg">{txMessage}</p>
      </div>
    );
  }

  return (
    <>
      {/* Title */}
      <h3 className="pageTitle">MY FM NODES</h3>

      {/* My info */}
      <Row className="mt-md-5 mt-2 mb-4 justify-content-center">
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center">
          <h6 className="fw-normal mb-0 pt-2">
            <small style={{ color: "#fff" }}>Generating daily:</small>{" "}
            <img
              src={logoToken}
              className="tokenIconColor"
              style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }}
              alt={SETTINGS.tokenSymbol}
            />
            <b>
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              }).format(totalDailyRewards)}{" "}
              {SETTINGS.tokenSymbol}
            </b>
          </h6>
        </Col>
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center px-5 mx-1">
          <h6 className="fw-normal mb-0 pt-2">
            <small style={{ color: "#fff" }}>Unclaimed:</small>{" "}
            <img
              src={logoToken}
              className="tokenIconColor"
              style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }}
              alt={SETTINGS.tokenSymbol}
            />
            <b>
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              }).format(totalRewards)}{" "}
              {SETTINGS.tokenSymbol}
            </b>
          </h6>
        </Col>
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center">
          <h6 className="fw-normal mb-0 pt-2">
            <small style={{ color: "#fff" }}>Unclaimed:</small>{" "}
            <img
              src={SETTINGS.usdtIcon}
              className="tokenIconColor"
              style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }}
              alt={SETTINGS.tokenSymbol}
            />
            <b>
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4,
              }).format(unclaimedUsdc)}{" "}
              USDC
            </b>
          </h6>
        </Col>
      </Row>

      {/* Claim Rewards Button */}
      <Row className="mt-4 justify-content-center">
        <Col xs="auto">
          <Button onClick={claimRewards} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Claim All Token Rewards"}
          </Button>
        </Col>
      </Row>

      <hr />

      <Row className="mt-0 mb-md-4 mb-0 justify-content-md-center justify-content-start">
        {ownedQworlds.map((nft, index) => (
          <Col xs={6} sm="auto" className="mb-3" key={index} style={{ padding: "0 8px" }}>
            <CardNode
              nft={nft}
              provider={provider}
              address={address}
              getBalanceFunction={getBalance}
              amount={NFTTypeCount[nft.tokenType]}
            />
          </Col>
        ))}
      </Row>

      <NodeExtraInfo />
    </>
  );
}

export default NodesList;
